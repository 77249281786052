import jsXLS from "js-export-excel";

class XLSjs {

    Inventario(empresa,data) {
        var option = {};
        option.fileName = "Inventario de "+empresa.nombre;
        var body = [];
    
        for (var i = 0; i < data.length; i++) {
			body.push({
                C1: i+1,
                C2: data[i].name,
                C3: data[i].marca,
                C4: data[i].code,
                C5: data[i].vehiculo,
                C6: data[i].categoria,
                C7: data[i].disponibles,
                C8: parseFloat(data[i].preciototal),
                C9: data[i].id,
            });
        }

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9"],
            sheetHeader: ['Nº','Produto', 'Marca', 'Código', 'Vehículo', 'Categoría', 'Disponibilidad', 'Precio', 'ID'],
            columnWidths: [2, 10, 10, 5, 10, 10, 5, 5, 3],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    Contabilidad(empresa,data) {
        var option = {};
        option.fileName = "Contabilidad de "+empresa.nombre;
        var body = [];
        var monto = null;
        var montobs = null;
        for (var i = 0; i < data.length; i++) {
            monto = null;
            montobs = null;   
            if(data[i].moneda==1){
                monto = parseFloat(data[i].monto);
            } else {
                montobs = parseFloat(data[i].montobs);
            }
			body.push({
                C1: i+1,
                C2: data[i].fechad,
                C3: data[i].control,
                C4: data[i].descripcion,
                C5: data[i].tipo_nombre,
                C6: data[i].referencia,
                C7: monto,
                C8: parseFloat(data[i].balance),
                C9: montobs,
                C10: parseFloat(data[i].balancebs),
            });
        }
        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8","C9","C10"],
            sheetHeader: ['Nº','Fecha', 'Nº Control', 'Descripción', 'Forma de Pago', 'Referencia', 'Monto $', 'Balance $', 'Monto Bs', 'Balance Bs'],
            columnWidths: [2, 5, 5, 30, 10, 10, 5, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    CuentaPagar(empresa,data) {
        var option = {};
        option.fileName = "Cuentas por Pagar de "+empresa.nombre;
        var body = [];
        var suma = 0;
        var sumabs = 0;
        var estatus = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].pagado){estatus = 'PAGADO';} else {estatus = 'PENDIENTE';}
            if(!data[i].activo){estatus = 'ANULADO';}
			body.push({
                C1: data[i].control,
                C2: data[i].prov_tipo,
                C3: data[i].fechad,
                C4: data[i].numero,
                C5: data[i].prov_nombre,
                C6: parseFloat(data[i].monto),
                C7: parseFloat(data[i].montobs),
                C8: estatus,
            });
            if(data[i].activo){
                suma = suma + parseFloat(data[i].monto);
                sumabs = sumabs + parseFloat(data[i].montobs);    
            }
        }
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: null,
            C5: null,
            C6: suma,
            C7: sumabs,
            C8: null,
        });

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8"],
            sheetHeader: ['Nº de Control','Tipo', 'Fecha', 'Nº', 'Proveedor', 'Monto $', 'Monto Bs', 'Estatus'],
            columnWidths: [7, 10, 5, 5, 20, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    CuentaCobrar(empresa,data) {
        var option = {};
        option.fileName = "Cuentas por Cobrar de "+empresa.nombre;
        var body = [];
        var suma = 0;
        var sumabs = 0;
        var estatus = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].pagado){estatus = 'PAGADO';} else {estatus = 'PENDIENTE';}
            if(!data[i].activo){estatus = 'ANULADO';}
			body.push({
                C1: data[i].control,
                C2: data[i].prov_tipo,
                C3: data[i].fechad,
                C4: data[i].numero,
                C5: data[i].prov_nombre,
                C6: parseFloat(data[i].monto),
                C7: parseFloat(data[i].montobs),
                C8: estatus,
            });
            if(data[i].activo){
                suma = suma + parseFloat(data[i].monto);
                sumabs = sumabs + parseFloat(data[i].montobs);    
            }
        }
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: null,
            C5: null,
            C6: suma,
            C7: sumabs,
            C8: null,
        });

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6","C7","C8"],
            sheetHeader: ['Nº de Control','Tipo', 'Fecha', 'Nº', 'Proveedor', 'Monto $', 'Monto Bs', 'Estatus'],
            columnWidths: [7, 10, 5, 5, 20, 5, 5, 5],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }

    CuentaAporte(empresa,data) {
        var option = {};
        option.fileName = "Aportes para "+empresa.nombre;
        var body = [];
        var suma = 0;
        var sumabs = 0;
        var estatus = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].pagado){estatus = 'PAGADO';} else {estatus = 'PENDIENTE';}
            if(!data[i].activo){estatus = 'ANULADO';}
			body.push({
                C1: data[i].control,
                C2: data[i].fechad,
                C3: data[i].prov_nombre,
                C4: parseFloat(data[i].monto),
                C5: parseFloat(data[i].montobs),
                C6: estatus,
            });
            if(data[i].activo){
                suma = suma + parseFloat(data[i].monto);
                sumabs = sumabs + parseFloat(data[i].montobs);    
            }
        }
        body.push({
            C1: null,
            C2: null,
            C3: null,
            C4: suma,
            C5: sumabs,
            C6: null,
        });

        option.datas = [{
            sheetName: "sheet",
            sheetFilter: ["C1","C2","C3","C4","C5","C6"],
            sheetHeader: ['Nº de Control', 'Fecha', 'Socio', 'Monto $', 'Monto Bs', 'Estatus'],
            columnWidths: [7, 7, 20, 5, 5, 7],
            sheetData: body,
        }];
        
        var xls = new jsXLS(option);
        xls.saveExcel();
    }









    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }
    
    formatCurrency(value) {
        return new Intl.NumberFormat("de-DE").format(parseFloat(value).toFixed(2));
    }

}


export default XLSjs;



//export default exports;