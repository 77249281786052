import axios from "axios";

let URL = {
  api: "./api/",
  local: "http://localhost/vue-project/Ventas/src/api/",
  prueba: "http://frenosvictoria.com.ve/api/",
};

export default parametros => {
  const http = axios.post(URL.prueba, parametros, { timeout: 60000 }); //60 segundos
  return http;
};