<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="cotizaciones" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Cotizaziones</h5>
							<Dropdown id="state" v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar" style="width: 300px;"></Dropdown>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb*2" @click="openNew" v-if="insert && empresa"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="control" header="Nº Control" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="cli_nombre"  header="Cliente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cli_nombre}}<br>
							RIF {{slotProps.data.emp_rif}}
						</template>
					</Column>
					<Column header="Monto" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(4))}}
						</template>
					</Column>
					<Column header="IVA" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.iva).toFixed(4))}}
						</template>
					</Column>
					<Column header="Total" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency((parseFloat(1+slotProps.data.iva/100)*parseFloat(slotProps.data.monto)).toFixed(4))}}
						</template>
					</Column>
					<Column  field="activo" header="Estatus" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							<div v-if="slotProps.data.activo">
							<strong v-if="slotProps.data.procesado" style="color:green">
								Procesado
							</strong>
							<strong v-else style="color:orange">
								Pendiente
							</strong>  
						</div>
						<div v-else>
							<strong style="color:grey">
								Anulado
							</strong> 
						</div>
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 200px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.procesado"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="editar(slotProps.data)" v-if="edit && slotProps.data.activo && slotProps.data.procesado"/>
							<Button icon="pi pi-print" class="p-button-rounded p-button-warning p-mr-2" @click="Presupuesto(slotProps.data)" v-if="edit && slotProps.data.activo"/>
							<Button icon="pi pi-play" class="p-button-rounded p-button-secondary p-mr-2" @click="procesar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.procesado"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmar(slotProps.data)" v-if="del && slotProps.data.activo && !slotProps.data.procesado"/>
						</template>
					</Column>
				</DataTable>

			</div>
		</div>

		<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
			<br>
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres anular el pedido?</span>
			</div>
			<template #footer>
				<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="anular" />
				<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
		</Dialog>

		<Dialog v-model:visible="EditDialog" :style="{width: size}" :header="'Editar Cotización ID '+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<br>
			{{product.cliente}}
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-4">
						<label for="name">Empresa</label>
						<InputText v-model="product.emp_nombre" disabled/>
					</div>
					<div class="p-col-2">
						<label for="name">Nº Control</label>
						<InputText v-model="product.control" disabled/>
					</div>
					<div class="p-col-2">
						<label for="name">Fecha</label>
						<InputText v-model="product.fecha" type="date"/>
					</div>
					
					<div class="p-col-4" >
						
						<label class="p-mb-3">
							Rif 
							<strong v-if="product.cliente">
								{{product.cliente.rif}}
							</strong>
						</label>
						<Dropdown id="state" v-model="product.cliente" :options="clientes" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
					</div>			
					<div class="p-col-2">
						<label for="price">Monto $</label>
						<div class="p-inputgroup">
							<InputNumber v-model="product.monto" mode="currency" currency="USD" locale="en-US" disabled/>
							<span class="p-inputgroup-addon">
								<RadioButton name="moneda" value="1" v-model="product.opcion"  :disabled="product.pagado"/>
							</span>
						</div>
					</div>
					<div class="p-col-2">
						<label for="price">Monto Bs</label>
						<div class="p-inputgroup">
							<InputNumber v-model="product.montobs" mode="currency" currency="VEF" locale="en-US"  disabled/>
							<span class="p-inputgroup-addon">
								<RadioButton name="moneda" value="0" v-model="product.opcion"  :disabled="product.procesado"/>
							</span>
						</div>
					</div>
					<div class="p-col-2">
						<label for="price">Tasa</label>
						<InputNumber v-model="product.tasa" mode="currency" currency="VEF" locale="en-US"  :disabled="product.pagado"/>
					</div>
					<div class="p-col-2">
						<label for="price" v-if="product.opcion == '1'">IVA ${{formatCurrency(parseFloat(product.monto*(product.iva/100)).toFixed(2))}}</label>
						<label for="price" v-if="product.opcion == '0'">IVA Bs {{formatCurrency(parseFloat(product.montobs*(product.iva/100)).toFixed(2))}}</label>
						<InputNumber v-model="product.iva" suffix="%" :disabled="product.pagado"/>
					</div>
					<div class="p-col-2">
						<h6 style="margin-top: 0">
							Factura: 
							<strong v-if="product.factura">SI</strong>
							<strong v-else>NO</strong>
						</h6>
						<InputSwitch v-model="product.factura" /> 	
					</div>
					<div class="p-col-2">
						<h6 style="margin-top: 0">
							Retención: 
							<strong v-if="product.retencion">SI</strong>
							<strong v-else>NO</strong>
						</h6>
						<InputSwitch v-model="product.retencion" /> 	
						<strong v-if="product.opcion == '1' && product.retencion">
							${{formatCurrency(parseFloat(product.monto*(product.iva/100)*(3/4)).toFixed(2))}}
						</strong>
						<strong v-if="product.opcion == '0' && product.retencion">
							Bs.{{formatCurrency(parseFloat(product.montobs*(product.iva/100)*(3/4)).toFixed(2))}}
						</strong>
					</div>
					<div class="p-col-11" v-if="product.detalle.length>0">
						<DataTable :value="product.detalle" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id" >
							<Column header="Código" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data.code}}<br>
									ID{{slotProps.data.id}}
								</template>
							</Column>
							<Column header="Descripción" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.marca}}<br>
									{{slotProps.data.name}}
								</template>
							</Column>
							<Column field="name" header="Cantidad" headerStyle="width: 100px">
								<template #body="slotProps">
									<InputText type="number" v-model="slotProps.data.cantidad"  :min="1"  style="max-width:50px;" @change="Calcular()"/>
								</template>
							</Column>
							<Column field="name" header="Descuento" headerStyle="width: 100px">
								<template #body="slotProps">
									<InputText type="number" v-model="slotProps.data.descuento"  :min="0" :max="20"  style="max-width:50px;" @change="Calcular()"/>
								</template>
							</Column>
							<Column header="Precio" headerStyle="width: 100px">
								<template #body="slotProps">
									Bs.{{formatCurrency(parseFloat(slotProps.data.cantidad*slotProps.data.monto*product.tasa).toFixed(2))}}
									<br>
									${{formatCurrency(parseFloat(slotProps.data.cantidad*slotProps.data.monto).toFixed(2))}}
								</template>
							</Column>
							<Column headerStyle="width: 75px">
								<template #body="slotProps"> 
									<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="DeleteProduct(slotProps.data)" />
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-col-1">
						<Button icon="bi bi-plus-square" class="p-button-rounded p-button-warning p-mr-2" @click="DialogProductos = true" v-if="!product.procesado && product.activo"/>
					</div>
				</div>
			</div>
			<template #footer>
				<h3>
					<strong v-if="product.opcion == '1'">
						<div v-if="product.retencion">
							Monto a Pagar: ${{formatCurrency(parseFloat(product.monto*(1+product.iva/400)).toFixed(2))}}
						</div>
						<div v-else>
							Monto a Pagar: ${{formatCurrency(parseFloat(product.monto*(1+product.iva/100)).toFixed(2))}}
						</div>
						
					</strong>
					<strong v-else>
						<div v-if="product.retencion">
							Monto a Pagar: Bs.{{formatCurrency(parseFloat(product.montobs*(1+product.iva/400)).toFixed(2))}}
						</div>
						<div v-else>
							Monto a Pagar: Bs.{{formatCurrency(parseFloat(product.montobs*(1+product.iva/100)).toFixed(2))}}
						</div>
					</strong>
				</h3>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="EditDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="DialogProductos" :style="{width: size}" header="Productos" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<DataTable :value="productos" :scrollable="true" scrollHeight="200px"  v-model:selection="selectedCustomers" :filters="filters2" class="p-datatable-sm">
									<template #header>
										<div class="table-header">
											<h5 class="p-m-0">Buscar </h5>
											<span class="p-input-icon-left">
												<i class="pi pi-search" />
												<InputText v-model="filters2['global']" placeholder="Buscar..." />
											</span>
										</div>
									</template>
									<Column field="name" header="Producto" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.name}} - ID{{slotProps.data.id}}
										</template>
									</Column>
									<Column field="code" header="Código" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.code}}
										</template>
									</Column>
									<Column field="marca" header="Marca" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.marca}}
										</template>
									</Column>
									<Column field="description" header="Descripción" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.description}}
										</template>
									</Column>
									<Column field="precio" header="Precio" :sortable="true">
										<template #body="slotProps">
											<Button icon="bi bi-plus-square" class="p-button-rounded p-button-success p-mr-2" @click="AgregarProducto(slotProps.data)" v-if="edit"/>
											${{formatCurrency(parseFloat(slotProps.data.precio).toFixed(2))}}
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProductos = false"/>
					</template>
				</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";

	export default {
		data() {
			return {
				nuevo: false,
				insert: false,
				edit: false,
				del: false,
				cotizaciones: [],
				filters: {},
				selectedProducts: null,
				selectedProducts2: null,
				filters2: {},
				submitted: false,
				size: '60vw',
				deleteProductDialog: false,
				EditDialog: false,
				DialogProductos: false,
				product: {},
				empresa: null,
				empresas: [],
				clientes: [],
				productos: [],
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				const Consulta = new API('Pedidos');
				Consulta.Ini('Cotizaciones').then(result => {
					//this.$store.state.error = result.consult.cotizaciones[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.empresas = result.consult.empresas;
					this.clientes = result.consult.clientes;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if(this.cotizaciones){
					this.cotizaciones  = [];
					this.productos  = [];
					const Consulta = new API('Pedidos');
					Consulta.Procesar('Cotizaciones',{
						caso: 'Buscar',
						empresa: this.empresa.id,
					}).then(response => {
						//this.$store.state.error = response.result.cotizaciones;
						this.cotizaciones = response.result.cotizaciones;
						this.productos = response.result.productos;
						this.$store.commit('Loading'); 
					});  
				}
			},
			Presupuesto(data){
				const doc = new PDF();
				doc.Presupuesto(this.$store.state.fecha,data,this.empresa);
			},
			openNew() {
				this.nuevo = true;
				this.product = {
					id: 0,
					cliente: null,
					control: '',
					fecha: this.$store.state.fecha,
					monto: 0,
					montobs: 0,
					iva: 0,
					tasa: this.$store.state.tasa,
					moneda: false,
					opcion: "0",
					procesado: false,
					retencion: false,
					factura: false,
					activo: true,
					detalle: [],
				};
				this.product.destino = {nombre: 'Cliente', code: 0};
				this.product.empresa = this.empresa.id;
				this.product.emp_nombre = this.empresa.nombre;
				this.product.rif = this.empresa.rif;
				this.EditDialog = true;
			},
			editar(product) {
				this.nuevo = false;
				this.product = {...product};
				this.clientes.forEach(element => {
					if(this.product.cliente.id==element.id){
						this.product.cliente = element;
					}
				});
				if(this.product.moneda){
					this.product.opcion = "1";
				} else {
					this.product.opcion = "0";
				}
				this.EditDialog = true;
			},
			AgregarProducto(data) {
				this.product.detalle.push({
					id: 0,
					producto: data.id,
					code: data.code,
					marca: data.marca,
					name: data.name,
					monto: data.precio,
					cantidad: 1,
					descuento: 0,
				});
				this.Calcular();
				this.DialogProductos = false;
			},
			Ver(data){
				//this.$store.state.error = data;
				const doc = new PDF();
				doc.Pedido(data);
			},
			confirmar(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			DeleteProduct(product) {
				this.product.detalle = this.product.detalle.filter(val => val.id !== product.id);
				this.Calcular();
			},
			Calcular(){
				var total = 0;
				this.product.detalle.forEach(element => {
					total = total + parseFloat(element.monto)*parseFloat(element.cantidad);
				}); 
				this.product.monto = total;
				this.product.montobs = total*parseFloat(this.product.tasa);
			},
			anular() {
					const Consulta = new API('Pedidos');          
					Consulta.Procesar('Cotizaciones',{
						caso: 	'DEL',
						id: 	this.product.id
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.deleteProductDialog = false;
							this.product = {};
							this.$store.commit('Informacion');
							this.$store.commit('Loading');
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
					
			},
			saveProduct() {
				if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha del Documento', life: 5000});
				} else if (this.product.cliente == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Cliente', life: 5000});
				} else if (this.product.detalle.len4 == 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Agregue al menos un producto', life: 5000});
				} else {
					const Consulta = new API('Pedidos');          
					if (!this.nuevo) {
						Consulta.Procesar('Cotizaciones',{
							caso: 	'UPD',
							product: 	this.product,
						}).then(response => {
							//this.$store.state.error = response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.EditDialog = false;
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					} else {
						Consulta.Procesar('Cotizaciones',{
							caso: 	'INS',
							product: 	this.product,
						}).then(response => {
							//this.$store.state.error = response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.EditDialog = false;
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					}
				}
			
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            formatCurrency(value) {
				return new Intl.NumberFormat("de-DE").format(parseFloat(value));
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
