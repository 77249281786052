<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="pedidos" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Pedidos Activos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="Nº" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column header="Empresa" :sortable="true" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.empresa.nombre}}
						</template>
					</Column>

					<Column field="cliente.nombre"  header="Cliente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cliente.nombre}}
						</template>
					</Column>
					<Column header="Monto" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(4))}}
						</template>
					</Column>
					<Column header="IVA" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.iva).toFixed(4))}}
						</template>
					</Column>
					<Column header="Total" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency((parseFloat(slotProps.data.iva)+parseFloat(slotProps.data.monto)).toFixed(4))}}
						</template>
					</Column>
					<Column  field="tipoventa" header="Venta Por" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.tipoventa}}
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 100px">
						<template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)  && slotProps.data.activo"/>
							<Button icon="pi pi-check-circle" class="p-button-rounded p-button-success" v-if="!slotProps.data.pendiente"/>
							<Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined" v-if="!slotProps.data.activo"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmar(slotProps.data)" v-if="del  && slotProps.data.activo && slotProps.data.pendiente"/>
						</template>
					</Column>
				</DataTable>

			</div>
		</div>

		<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
			<br>
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres anular el pedido?</span>
			</div>
			<template #footer>
				<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="anular" />
				<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
		</Dialog>



	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				pedidos: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				deleteProductDialog: false,
				product: {},
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.pedidos  = [];
				const Consulta = new API('Pedidos');
				Consulta.Ini('Pedidos').then(result => {
					//this.$store.state.error = result.consult.pedidos[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.pedidos = result.consult.pedidos;
					this.$store.commit('Loading');
				}); 
			},
			Ver(data){
				//this.$store.state.error = data;
				const doc = new PDF();
				doc.Pedido(data);
			},
			confirmar(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			anular() {
					const Consulta = new API('Pedidos');          
					Consulta.Procesar('Pedidos',{
						caso: 	'DEL',
						id: 	this.product.id
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Informacion');
							this.seleccion= this.categselec;
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
					this.deleteProductDialog = false;
					this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            formatCurrency(value) {
				return new Intl.NumberFormat("de-DE").format(parseFloat(value));
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
