<template>
	<div class="p-grid">
		<div class="p-grid dashboard p-col-12">
			<div class="p-col-12 p-lg-2">
				<div class="card summary">
					<span class="title">Total</span>
					<span class="count visitors">${{formatCurrency(parseFloat(total).toFixed(2))}}</span>
				</div>
			</div>
			<div class="p-col-12 p-lg-2">
				<div class="card summary">
					<span class="title">Pagado</span>
					<span class="count revenue">${{formatCurrency(parseFloat(pagado).toFixed(2))}}</span>
				</div>
			</div>
			<div class="p-col-12 p-lg-2">
				<div class="card summary">
					<span class="title">Pendiente</span>
					<span class="count purchases">${{formatCurrency(parseFloat(pendiente).toFixed(2))}}</span>
				</div>
			</div>
		</div>
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="pagos" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Aportes: {{pagos.length}}</h5>
							<Dropdown id="state" v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar" ></Dropdown>
							<Dropdown id="state" v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar" style="width: 300px;"></Dropdown>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb*2" @click="openNew" v-if="insert && empresa"/>
							<Button label="Imprimir" icon="pi pi-print" class="p-button-warning p-mr-2 p-mb*2" @click="Imprimir" v-if="insert && empresa"/>
							<Button label="Descargar" icon="pi pi-download" class="p-button-warning p-mr-2 p-mb*2" @click="Descargar" v-if="empresa"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="control" header="Nº Control" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.control}}
							<Checkbox id="binary" v-model="slotProps.data.imp" :binary="true"/>
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="prov_nombre" header="Proveedor" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.prov_nombre}}
						</template>
					</Column>
					<Column field="prov_rif" header="Rif" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.prov_rif}}
						</template>
					</Column>
					<Column header="Monto" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.moneda">
								${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(4))}}
							</div>
							<div v-else>		
								Bs{{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(4))}}
							</div>  
						</template>
					</Column>
					<Column header="Estatus" :sortable="true">
						<template #body="slotProps">
						<div v-if="slotProps.data.activo">
							<strong v-if="slotProps.data.pagado" style="color:green">
								Pagado
							</strong>
							<strong v-if="!slotProps.data.pagado && (slotProps.data.recibido>0 || slotProps.data.recibidobs>0)" style="color:orange">
								Pago Parcial<br>
								<div v-if="moneda==1">${{formatCurrency(parseFloat(slotProps.data.recibido).toFixed(2))}}</div>
								<div v-if="moneda==0">Bs{{formatCurrency(parseFloat(slotProps.data.recibidobs).toFixed(2))}}</div>
							</strong>  
							<strong v-if="!slotProps.data.pagado && slotProps.data.recibido==0 && slotProps.data.recibidobs==0" style="color:red">
								Pendiente
							</strong>  
						</div>
						<div v-else>
							<strong style="color:grey">
								Anulado
							</strong> 
						</div>
						</template>
					</Column>				
					<Column header="Opciones" headerStyle="width: 220px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.pagado"/>
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="editar(slotProps.data)" v-if="edit && slotProps.data.activo && slotProps.data.pagado"/>
							<!--<Button icon="pi pi-print" class="p-button-rounded p-button-warning p-mr-2" @click="OrdenPago(slotProps.data)" v-if="edit && slotProps.data.activo"/>-->
							<Button icon="bi bi-files" class="p-button-rounded p-button-info p-mr-2" @click="duplicar(slotProps.data)" v-if="insert"/>
							<Button icon="pi pi-play" class="p-button-rounded p-button-secondary p-mr-2" @click="procesar(slotProps.data)" v-if="edit && slotProps.data.activo && !slotProps.data.pagado"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmar(slotProps.data)" v-if="del && slotProps.data.activo && !slotProps.data.pagado"/>
						</template>
					</Column>

				</DataTable>

			</div>
		</div>

		<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
			<br>
			<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres anular?</span>
			</div>
			<template #footer>
				<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="anular" />
				<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
		</Dialog>

		<Dialog v-model:visible="ProductDialog" :style="{width: size}" :header="'Editar Aporte ID '+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-7">
						<label for="name">Empresa</label>
						<InputText v-model="product.emp_nombre" disabled/>
					</div>
					<div class="p-col-2">
						<label for="name">Nº Control</label>
						<InputText v-model="product.control" disabled/>
					</div>
					<div class="p-col-3">
						<label for="name">Fecha</label>
						<InputText v-model="product.fecha" type="date"/>
					</div>
					<div class="p-col-4">
						<label class="p-mb-3">Socio</label>
						<Dropdown id="state" v-model="product.proveedor" :options="proveedores" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
					</div>
					<div class="p-col-2">
						<label for="name">Rif</label>
						<InputText v-model="product.proveedor.rif" disabled v-if="product.proveedor"/>
					</div>
					<div class="p-col-2">
						<label for="price">Monto $</label>
						<div class="p-inputgroup">
							<InputNumber v-model="product.monto" mode="currency" currency="USD" locale="en-US" :disabled="product.pagado"/>
							<span class="p-inputgroup-addon">
								<RadioButton name="moneda" value="1" v-model="product.opcion" :disabled="product.pagado"/>
							</span>
						</div>
					</div>
					<div class="p-col-2">
						<label for="price">Monto Bs</label>
						<div class="p-inputgroup">
							<InputNumber v-model="product.montobs" mode="currency" currency="VEF" locale="en-US" :disabled="product.pagado"/>
							<span class="p-inputgroup-addon">
								<RadioButton name="moneda" value="0" v-model="product.opcion" :disabled="product.pagado"/>
							</span>
						</div>
					</div>
					<div class="p-col-2">
						<label for="price">Tasa</label>
						<InputNumber v-model="product.tasa" mode="currency" currency="VEF" locale="en-US" :disabled="product.pagado"/>
					</div>
					<div class="p-col-6">
						<label for="name">Descripción</label>
						<Textarea v-model="product.descripcion" rows="3" cols="20" />
					</div>
					<div class="p-col-6">
						<label for="name">Observación</label>
						<Textarea v-model="product.observacion" rows="3" cols="20" />
					</div>
					<div class="p-col-7" v-if="detallepago.length>0">
						<DetallePago :detallepago="detallepago"/>
					</div>
					<div class="p-col-1" v-if="detallepago.length>0">
						<Button icon="pi pi-book" class="p-button-rounded p-button-warning p-mr-2" @click="Recibo"/>
					</div>
				</div>
			</div>
			<template #footer>
				<h3>
					<strong v-if="product.opcion == '1'">
						Monto de Aporte: ${{formatCurrency(parseFloat(product.monto).toFixed(2))}}
					</strong>
					<strong v-else>
						Monto de Aporte: Bs.{{formatCurrency(parseFloat(product.montobs).toFixed(2))}}
					</strong>
				</h3>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="ProductDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="ProcesarDialog" :style="{width: size}" header="Procesar" :modal="true" class="p-fluid" :maximizable="true">
			<Reportar/>
		</Dialog>

		<Dialog v-model:visible="DialogProcesar" :style="{width: size}" header="Procesar Pago" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<br>
			<div class="p-field">
				<div class="p-grid dashboard">
					<div class="p-col-12 p-lg-6">
						<div class="highlight-box">
							<div class="initials" style="background-color: #20d077; color: #038d4a; width: 30%;"><span>$</span><br>{{formatCurrency(cajaf)}}</div>
							<div class="highlight-details ">
								<i class="bi bi-cash"></i>
								<span>Caja</span>
								<span class="count">{{formatCurrency(caja)}}</span>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-lg-6">
						<div class="highlight-box">
							<div class="initials" style="background-color: #f9c851; color: #b58c2b; width: 30%;"><span>Bs</span><br>{{formatCurrency(bancof)}}</div>
							<div class="highlight-details ">
								<i class="bi bi-credit-card"></i>
								<span>Banco</span>
								<span class="count">{{formatCurrency(banco)}}</span>
							</div>
						</div>
					</div>
					<div class="p-col-8">
						<ReportarPago :tipopago="formapagos" :deuda="totales" :moneda="moneda"/>
					</div>
					<div class="p-col-4">
						<div class="card">
							<Card>
								<template #content>
									<div class="p-fluid p-formgrid p-grid">
										<div class="p-field p-col-12 p-md-12" >
											<div class="p-fluid p-formgrid p-grid">
												<div class="p-field p-col-12">
													<h5>{{product.emp_nombre}}</h5>
												</div>
												<div class="p-field p-col-12">
													<h5>Control Nº {{product.control}}</h5>
												</div>
												<div class="p-field p-col-12">
													<h5 v-if="moneda==1">Monto: ${{formatCurrency(product.monto)}}</h5>
													<h5 v-if="moneda==0">Monto: Bs{{formatCurrency(product.montobs)}}</h5>
												</div>
												<div class="p-field p-col-12" v-if="product.recibido<0 && product.recibidobs<0">
													<h5 v-if="moneda==1">Pago Parcial: ${{formatCurrency((-1)*product.recibido)}}</h5>
													<h5 v-if="moneda==0">Pago Parcial: Bs{{formatCurrency((-1)*product.recibidobs)}}</h5>
												</div>
											</div>
										</div>
										<div class="p-field p-col-12 p-md-12" >
											Fecha: {{product.fechad}}
										</div>
									</div>
								</template>
								<template #footer>	
									<strong v-if="$store.state.pago.monto >= product.monto && moneda==1" style="color:green">
										<h5>Pago Total</h5>
									</strong>
									<strong v-if="$store.state.pago.montobs >= product.montobs && moneda==0" style="color:green">
										<h5>Pago Total</h5>
									</strong>
									<strong v-if="$store.state.pago.monto<parseFloat(product.monto-product.recibido) && $store.state.pago.monto>0 && moneda==1" style="color:orange">
										<h5>Pago Parcial</h5>
									</strong>
									<strong v-if="$store.state.pago.montobs<parseFloat(product.montobs-product.recibidobs) && $store.state.pago.montobs>0  && moneda==0" style="color:orange">
										<h5>Pago Parcial</h5>
									</strong>
									<strong v-if="$store.state.pago.montobs==0 && $store.state.pago.monto==0 && product.recibido==0 && product.recibidobs==0" style="color:red">
										<h5>Pendiente</h5>
									</strong>  
								</template>
							</Card>	
						</div>
					</div>

				</div>
				
			</div>
			
			<template #footer>
				<Button label="Procesar" icon="pi pi-check" class="p-button-secondary" @click="Ejecutar()" 
					:disabled="$store.state.pago.monto==0 && $store.state.pago.montobs==0"/>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProcesar = false"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import ReportarPago from '@/components/ReportarPago.vue';
    import XLS from "../service/XLS";
	import DetallePago from '@/components/DetallePago.vue';

	export default {
		components: {
			ReportarPago,
			DetallePago,
		},
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				empresa: null,
				empresas: [],
				proveedores: [],
				pagos: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				ProductDialog: false,
				deleteProductDialog: false,
				product: {},
				nuevo: false,
				opcion: {nombre: 'Todas', code: 0},
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Pagadas', code: 1},
					{nombre: 'Pendientes', code: 2},
					{nombre: 'Anuladas', code: 3}
				],
				fecha: null,
				total: 0,
				pagado: 0,
				pendiente: 0,
				DialogProcesar: false,
				banco: 0,
				caja: 0,
				bancof: 0,
				cajaf: 0,
				tipopago: [],
				totales: {
                    subtotal: 0,
                    descuento: 0,
					final: 0,
                },
				formapagos: [],
				moneda: 1,
				detallepago: []
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.empresas = [];
				this.proveedores = [];
				const Consulta = new API('Administracion');
				Consulta.Ini('Aportes').then(result => {
					//this.$store.state.error = result.consult;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.empresas = result.consult.empresas;
					this.proveedores = result.consult.proveedores;
					this.tipopago = result.consult.tipopago;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if(this.empresa && this.opcion){
					this.pagos = [];
					const Consulta = new API('Administracion');
					Consulta.Procesar('Aportes',{
						caso: 'Buscar',
						empresa: this.empresa.id,
						opcion: this.opcion.code
					}).then(response => {
						//this.$store.state.error = response.result.pagos[0];
						this.pagos = response.result.pagos;
						this.Totales();
						this.$store.commit('Loading'); 
					});  
				}
			},
			Descargar(){
				if(this.empresa){
					const xls = new XLS();
					xls.CuentaAporte(this.empresa,this.pagos);
				}
			},
			Totales(){
				this.total = 0;
				this.pagado = 0;
				this.pendiente = 0;
				this.pagos.forEach(element => {
					this.total = parseFloat(this.total) + parseFloat(element.monto);
					if(element.pagado && element.activo){
						this.pagado = parseFloat(this.pagado) + parseFloat(element.monto);
					}
					if(!element.pagado && element.activo){
						this.pendiente = parseFloat(this.pendiente) + parseFloat(element.monto);
					}
				}); 
			},
			openNew() {
				this.nuevo = true;
				this.product = {
					control: '',
					fecha: null,
					vence: false,
					proveedor: null,
					descripcion: '',
					monto: 0,
					montobs: 0,
					moneda: false,
					opcion: "0",
					tasa: this.$store.state.tasa,
					pagado: false,
					destino: null
				};
				this.product.empresa = this.empresa.id;
				this.product.emp_nombre = this.empresa.nombre;
				this.product.rif = this.empresa.rif;
				this.ProductDialog = true;
			},
			Imprimir(){
				const doc = new PDF();
				var data;
				data = this.pagos.filter(val => val.imp == true);
				if (data.length>0){
					doc.CAporte(this.empresa,data);
				} else {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione Documento a Imprimir', life: 5000});
				}
			},
			OrdenPago(data){
				const doc = new PDF();
				doc.OrdenPago(this.empresa,data);			
			},
			duplicar(product) {
				this.nuevo = true;
				this.product = {...product};
				this.proveedores.forEach(element => {
					if(this.product.proveedor==element.id){
						this.product.proveedor = element;
					}
				}); 
				if(this.product.moneda){
					this.product.opcion = "1";
				} else {
					this.product.opcion = "0";
				}
				this.product.control = '';
				this.product.fecha = null;
				this.ProductDialog = true;
			},
			editar(product) {
				this.nuevo = false;
				this.product = {...product};
				this.proveedores.forEach(element => {
					if(this.product.proveedor==element.id){
						this.product.proveedor = element;
					}
				}); 
				if(this.product.moneda){
					this.product.opcion = "1";
				} else {
					this.product.opcion = "0";
				}
				const Consulta = new API('Administracion');          
				Consulta.Procesar('Aportes',{
					caso: 	'Pagos',
					id: 	this.product.id
				}).then(response => {
					this.detallepago = response.result;
					this.$store.commit('Loading'); 
					this.ProductDialog = true;
				});		
			},
			Recibo(){
				const doc = new PDF();
				doc.Recibo(this.$store.state.fecha,this.product,this.detallepago,false);
			},
			saveProduct() {
				if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha del Documento', life: 5000});
				} else if (this.product.proveedor == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Proveedor', life: 5000});
				} else if (this.product.descripcion == null || this.product.descripcion == '') {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca una Descripción del Documento', life: 5000});
				} else {
					if(this.product.opcion == '0' && this.product.tasa>0  && this.product.montobs>0 && this.product.monto == '0'){
						this.product.monto = parseFloat(this.product.montobs/this.product.tasa).toFixed(2);
					}
					if(this.product.opcion == '1' && this.product.tasa>0  && this.product.monto>0 && this.product.montobs == '0'){
						this.product.montobs = parseFloat(this.product.monto*this.product.tasa).toFixed(2);
					}
					const Consulta = new API('Administracion');          
					if (!this.nuevo) {
						Consulta.Procesar('Aportes',{
							caso: 	'UPD',
							product: 	this.product,
						}).then(response => {
							//this.$store.state.error = response.result;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.ProductDialog = false;
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					} else {
						Consulta.Procesar('Aportes',{
							caso: 	'INS',
							product: 	this.product,
							empresa: 	this.empresa.id,
						}).then(response => {
							//this.$store.state.error = response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.ProductDialog = false;
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					}
				}
			
			},
			confirmar(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			anular() {
					const Consulta = new API('Administracion');          
					Consulta.Procesar('Aportes',{
						caso: 	'DEL',
						id: 	this.product.id
					}).then(response => {
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Loading');
							this.deleteProductDialog = false;
							this.product = {};
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
					
			},
			procesar(product) {
				this.product = {...product};
				this.caja = 0;
				this.banco = 0;
				const Consulta = new API('Administracion');          
				Consulta.Procesar('Aportes',{
					caso: 	'Saldos',
					empresa: 	product.empresa,
					fecha:		product.fecha,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.caja = response.result.caja;
						this.banco = response.result.banco;
						this.cajaf = response.result.cajaf;
						this.bancof = response.result.bancof;
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('LimpiarPagos');
						this.$store.state.tasa = product.tasa;
                        this.totales.final = parseFloat(product.monto)-parseFloat(product.recibido);
						this.totales.finalbs = parseFloat(product.montobs)-parseFloat(product.recibidobs);
						if(product.moneda){
							this.moneda = 1;
							this.formapagos = this.tipopago;
						} else {
							this.moneda = 0;
							this.formapagos = this.tipopago;
						}
						this.DialogProcesar = true;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading'); 
				});
			},
			Ejecutar() {
				if (!this.product == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione un intercambio', life: 5000});
				} else if (this.$store.state.pago.monto==0 && this.$store.state.pago.montobs==0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la forma de pago', life: 10000});
				} else {
					const Consulta = new API('Administracion');          
					Consulta.Procesar('Aportes',{
						caso: 	'Procesar',
						seleccion: 	this.product,
						pagos: 		this.$store.state.pago,
						tasa: 		this.$store.state.tasa,
						moneda: 	this.moneda,
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.DialogProcesar = false;
							this.$store.commit('LimpiarPagos');
							this.$store.commit('Loading');
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
						
					});
				}
			},

			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            formatCurrency(value) {
				return new Intl.NumberFormat("de-DE").format(parseFloat(value));
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
