<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Simulador Precios" icon="bi bi-calculator-fill" class="p-button-info p-mr-2 p-mb*2" @click="Simulador"/>
					</template>
					<template v-slot:right>
                    </template>
				</Toolbar>
				
				<div class="p-grid p-fluid dashboard" >
					<div class="p-col-12 p-lg-4">
						<div class="card summary">
							<span class="title">Categoría   
								<i :class="seleccion.icon" style="color: #d46900;fontSize: 1.5rem" v-if="seleccion">{{seleccion.cant}}</i>
							</span>
							<span class="detail">
								<Dropdown v-model="seleccion" :options="$store.state.categoria" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<img style="width: 20px;" :alt="slotProps.value.id" :src="'images/categorias/' + slotProps.value.id + '.jpg'" />
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<img style="width: 20px;" :alt="slotProps.option.id" :src="'images/categorias/' + slotProps.option.id + '.jpg'" />
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion">{{seleccion.total}}</span>
							<span class="count visitors" v-else>0</span>
							
						</div>
					</div>
					<div class="p-col-12 p-lg-4">
						<div class="card summary">
							<span class="title">Proveedor</span>
							<span class="detail">
								<Dropdown v-model="seleccion2" :options="proveedores" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos2()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion2">{{products.length}}</span>
							<span class="count visitors" v-else>0</span>						
						</div>
					</div>
					<div class="p-col-12 p-lg-4" v-if="$store.state.empresa==0">
						<div class="card summary">
							<span class="title">Empresa</span>
							<span class="detail">
								<Dropdown v-model="seleccion3" :options="empresas" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos3()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion3">{{products.length}}</span>
							<span class="count visitors" v-else>0</span>						
						</div>
					</div>
				</div>

				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Productos a la Venta</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Imagen">
						<template #body="slotProps">
							<strong style="font-size: 8px">{{slotProps.data.empresanombre}}</strong><br><br>
							<img :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg" class="product-image" /><br>
							<strong style="font-size: 8px">{{slotProps.data.name}}</strong>
						</template>
					</Column>
					<Column field="id" header="ID" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}<br>
							<img :src="'images/marcas/'+ slotProps.data.marc +'.png'" :alt="slotProps.data.marca" class="marca-image" v-if="slotProps.data.marc>0"/><br>
							<img :src="'images/vehiculos/'+ slotProps.data.veh +'.png'" :alt="slotProps.data.tipovehiculo" class="marca-image" v-if="slotProps.data.veh>0"/>
						</template>
					</Column>
					<Column field="code" header="Código" :sortable="true"></Column>
					<Column header="Costo" :sortable="true">					
						<template #body="slotProps">
							${{formatCurrency(parseFloat(slotProps.data.costo).toFixed(2))}}
						</template>
					</Column>
					<Column field="price" header="Precio" :sortable="true">						
						<template #body="slotProps">
							Mínimo: ${{formatCurrency(parseFloat(slotProps.data.preciominimo).toFixed(2))}} 
							<br>
							<font color="red" v-if="slotProps.data.error || slotProps.data.preciominimo==0">Máximo: ${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</font>
							<font color="blue" v-else>Máximo: ${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</font>
							<br>
							<font color="orange"  v-if="slotProps.data.error  || slotProps.data.preciominimo==0">Descuento: 0%</font>
							<font color="orange" v-else>Descuento: {{(parseFloat((slotProps.data.preciototal-slotProps.data.preciominimo)*100/slotProps.data.preciototal).toFixed(2))}}%</font>

						</template>
					</Column>
					<Column field="price" header="Oferta" :sortable="true">						
						<template #body="slotProps">
							${{formatCurrency((parseFloat(slotProps.data.preciototal)-0.10*parseFloat(slotProps.data.preciototal)).toFixed(0))}} 
							<br>Rebaja del 10%
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" /><br><br>
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
							<br><br><span class="p-tag p-tag-info" v-if="slotProps.data.express">Express</span>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Product Details" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>ID {{product.id}}
					<img :src="'images/marcas/'+product.marc.id+'.png'" :alt="product.marc.nombre" class="marca-image" v-if="product.marc && product.marc.id>0"/>					
					<img :src="'images/vehiculos/'+product.veh.id+'.png'" :alt="product.veh.nombre" class="marca-image" v-if="product.veh && product.veh.id>0"/>					
					<img src="images/mercadolibre.png" class="marca-image"  v-if="product.mercadolibre"/>
					<img :src="$store.state.url_api+'upload_prod/'+product.imagen+'?version='+product.fecha_reg" :alt="product.id+'.jpg'" class="product-image" v-if="product.id>0"/>	
					<div class="p-field">
						<div class="p-grid">
							<div class="p-col-7">
								{{product.name}}
							</div>
							<div class="p-col-5">
								Código: {{product.code}}
							</div>
							<div class="p-col-10">
								Descripción: {{product.description}}
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Compra Express</h6>
								<InputSwitch v-model="product.express" />
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Proveedor</label>
								<Dropdown v-model="product.proveedor" :options="proveedores" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  :showClear="true">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</div>
							<div class="p-col-2">
								<label for="quantity">Unidad</label>
								<InputNumber id="quantity" v-model="product.unidad" min="1" integeronly />
							</div>
							<div class="p-col-2">
								<label for="price">Rentabilidad %</label>
								<InputNumber id="price" v-model="product.rentabilidad" min="0" max="100" />
							</div>
							<div class="p-col-2	">
								<label for="price">Comision %</label>
								<InputNumber id="price" v-model="product.comision" min="0" max="100" />
							</div>							
							<div class="p-col-2">
								<label for="price">Costo $ x Unidad</label>
								<InputNumber id="price" v-model="product.costo" mode="currency" currency="USD" locale="de-DE" />
							</div>
							<div class="p-col-2">
								<label for="price">Precio $ x Unidad</label>
								<InputNumber id="price" v-model="product.precio" mode="currency" currency="USD" locale="de-DE" />
							</div>
							<div class="p-col-2">
								<label for="price">Costo Total $</label>
								<InputNumber v-bind:value="'$'+(product.costo*product.unidad).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-2">
								<label for="price">Ganancia $</label>
								<InputNumber v-bind:value="'$'+(product.precio*product.unidad-((product.costo*product.unidad)*(1+product.rentabilidad/100)/(1-product.comision/100))).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-2">
								<label for="price">Rentabilidad $</label>
								<InputNumber v-bind:value="'$'+(product.costo*product.unidad*product.rentabilidad/100).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-2">
								<label for="price">Comisión $ (5%)</label>
								<InputNumber v-bind:value="'$'+(product.precio*product.unidad*product.comision/100).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-4">
								<label for="price">Precio Mínimo $ (Sin IVA)</label>
								<InputNumber v-bind:value="'$'+((((product.costo*product.unidad)*(1+product.rentabilidad/100)/(1-product.comision/100)))).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-4">
								<label for="price">Precio Máximo $  (Sin IVA) <font color="orange">{{((product.precio*product.unidad-((product.costo*product.unidad)*(1+product.rentabilidad/100)/(1-product.comision/100)))*100/(product.precio*product.unidad)).toFixed(2)}}%</font></label>
								<InputNumber v-bind:value="'$'+(product.precio*product.unidad).toFixed(2)" :disabled="true"/>
							</div>
							<div class="p-col-4">
								<label for="price">Precio Oferta $  (Sin IVA) <font color="orange">-10%</font></label>
								<InputNumber v-bind:value="'$'+(product.precio*0.9*product.unidad).toFixed(2)" :disabled="true"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
			</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
	<Simulador :display="displaySimulador" @aprob-simulador="onMenuToggle" @close-simulador="close"/>

</template>

<script>
import API from "../service/API";
import Simulador from '../components/SimuladorPrecio.vue';
import url from "../service/_URL";

export default {
	components: {
		Simulador
	},
	data() {
		return {
			displaySimulador: false,
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			size: '60vw',
			categorias: null,
			categselec: null,
			marcas: null,
			vehiculos: null,
			empresas: null,
			proveedores: null,
			modelos: null,
			seleccion: null,
			seleccion2: null,
			seleccion3: null
		}
	},
	productService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		this.url = url;
		this.Mostrar();
	},
	mounted() {
	},
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		Simulador() {
			this.$store.commit('Mobile');
			this.displaySimulador = true;
		},
		close() {
			this.displayPerfil = false;
			this.displaySimulador = false;
		},
		Mostrar() {
			const Consulta = new API('Inventario');
			Consulta.Ini('Costo').then(response => {
				//this.$store.state.error = result;
				this.insert = response.insert;
				this.edit = response.edit;
				this.del = response.delete;
				this.categorias=response.consult.sucategoria;
				this.marcas=response.consult.marcas;
				this.vehiculos=response.consult.vehiculos;
				this.empresas=response.consult.empresas;
				this.proveedores=response.consult.proveedores;
				this.modelos=response.consult.modelos;
				this.$store.commit('Loading');
			}); 
		},
		verproductos() {
			this.seleccion2 = null;
			this.seleccion3 = null;
			this.categ = this.seleccion.id;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Costo',{
				caso: 'categoria',
				id: this.seleccion.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		verproductos2() {
			this.seleccion = null;
			this.seleccion3 = null;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Costo',{
				caso: 'proveedor',
				id: this.seleccion2.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		verproductos3() {
			this.seleccion = null;
			this.seleccion2 = null;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Costo',{
				caso: 'empresa',
				id: this.seleccion3.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('de-DE', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		saveProduct() {
				if(!this.product.proveedor) {
					this.product.proveedor = {id: false};
				}
				this.productDialog = false;
                const Consulta = new API('Inventario');          
				if (this.product.id) {
					Consulta.Procesar('Costo',{
						caso: 	'UPD',
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error=response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Informacion');
							this.seleccion= this.categselec;
							this.productDialog = false;
							this.product = {};
							this.$store.commit('Loading'); 
							this.verproductos();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading'); 
						}
					});
				} 
				
		},
		editProduct(product) {
			this.product = {...product};
			this.categorias.forEach(element => {
				if(this.product.categ==element.id){
					this.categselec = element;
				}
			});  
			this.marcas.forEach(element => {
				if(this.product.marc==element.id){
					this.product.marc = element;
				}
			});  
			this.vehiculos.forEach(element => {
				if(this.product.veh==element.id){
					this.product.veh = element;
				}
			});  
			this.empresas.forEach(element => {
				if(this.product.empresa==element.id){
					this.product.empresa = element;
				}
			});  
			this.proveedores.forEach(element => {
				if(this.product.proveedor==element.id){
					this.product.proveedor = element;
				}
			});  
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Inventario');          
				Consulta.Procesar('Costo',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Informacion');
						this.seleccion= this.categselec;
						this.$store.commit('Loading');
						this.deleteProductDialog = false;
						this.product = {}; 
						this.verproductos();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading'); 
					}
				});
				
		},
			moneda(bs) {
				return bs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 40px;
}


.p-dialog .product-image {
	width: 60px;
	margin: 0 auto 1rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
