import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Num_Letra from './Num_Letras';

var pdf = new jsPDF();

class PDFs {

    Plantilla(empresa){
        pdf.addImage("images/empresas/"+empresa.id+".jpg", 'JPEG', 20, 10, 30, 25);

        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(100, 11, empresa.nombre, 'center');
        pdf.setFont("italic","normal");
        pdf.text(100, 14, empresa.direccion, 'center');
        pdf.text(100, 17, 'Tlf '+empresa.tlflocal, 'center');
        pdf.text(100, 20, 'Correo: '+empresa.correo, 'center');
    }

    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            columnStyles: {text: {columnWidth: 'auto'}, fillColor: [255, 255, 255], textColor: [0,0,0]},             
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fontSize: 8,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                halign: 'center', // left, center, right
            },
        });
    }

    TablaFact(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            columnStyles: {
                text: {columnWidth: 'auto'}, 
                2: {halign: "left"},
                //0: {halign: "right"},
                fillColor: [255, 255, 255], textColor: [0,0,0]
            },             
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fontSize: 8,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                halign: 'center', // left, center, right
            },
        });
    }

    TablaOrd(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 8, textColor: [0,0,0]},                     
            columnStyles: {text: {columnWidth: 'auto'}, fillColor: [255, 255, 255], textColor: [0,0,0]},             
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fontSize: 8,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                halign: 'left', // left, center, right
            },
        });
    }

    TablaAprob(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 7, textColor: [0,0,0]},                     
            columnStyles: {text: {columnWidth: 20}, fillColor: [255, 255, 255], textColor: [0,0,0]},   
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                columnWidth: 45,
                cellPadding: 2,
                fontSize: 7,
                halign: 'left', // left, center, right
            },
        });
    }

    TablaAprob2(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [255, 255, 255], fontSize: 7, textColor: [0,0,0]},                     
            columnStyles: {text: {columnWidth: 20}, fillColor: [255, 255, 255], textColor: [0,0,0]},   
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                fillColor: [255, 255, 255], 
                textColor: [0,0,0],
                columnWidth: 70,
                cellPadding: 1.5,
                fontSize: 7,
                halign: 'left', // left, center, right
            },
        });
    }

    Out(nombre){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"), nombre+".pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat("de-DE").format(parseFloat(number).toFixed(2));
    }
    
    Cotizacion(data) {

        this.Plantilla(data.empresa);     
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, 'PRESUPUESTO', 'center');
        pdf.setFontSize(10);
        pdf.setFont("italic","normal");
        pdf.text('Nº '+data.id, 170, 20);
        pdf.text('Fecha de Emisión '+data.fechad, 150, 30);
        pdf.setFontSize(10);
        pdf.text('Nombre o Razón Social: '+data.cliente.nombre, 20, 40);
        pdf.text('Rif: '+data.cliente.code+'-'+data.cliente.rif, 150, 40);
        pdf.text('Direccón: '+data.cliente.nombre, 20, 50);
        pdf.text('Tlef: '+data.cliente.tlflocal, 150, 50);
        pdf.text('Correo: '+data.cliente.correo, 20, 60);
        pdf.text('Celular: '+data.cliente.celular, 150, 60);

        pdf.rect(165, 13, 30, 10); 
        pdf.rect(145, 23, 50, 10); 
        pdf.rect(15, 33, 130, 10); 
        pdf.rect(145, 33, 50, 10); 
        pdf.rect(15, 43, 130, 10); 
        pdf.rect(145, 43, 50, 10); 
        pdf.rect(15, 53, 130, 10); 
        pdf.rect(145, 53, 50, 10); 

        var produc = data.productos;
        const head = [['Nº','Cant', 'Descripción', 'Código','Marca','Costo','Descuento','Monto']];
        var body = [];
  
        for (var i = 0; i < produc.length; i++) {
			body.push([
                i+1,
                produc[i].cantidad,
                produc[i].name,
                produc[i].code,
                produc[i].marca,
                '$'+this.moneda(parseFloat(produc[i].monto)+parseFloat(produc[i].descuento)),
                '$'+this.moneda(produc[i].descuento),
                '$'+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].cantidad))
            ]);
        }
        this.Tabla(15,70,head,body);     

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text('SUBTOTAL:', 130, 250);
        pdf.text('$'+this.moneda(data.monto), 170, 250);
        pdf.text('IVA:', 130, 260);
        pdf.text('$'+this.moneda(data.iva), 170, 260);
        pdf.text('TOTAL:', 130, 270);
        pdf.text('$'+this.moneda(parseFloat(data.monto)+parseFloat(data.iva)), 170, 270);

        this.Out('Cotizacion'+data.id);  
        pdf = new jsPDF();      
    }


    Pedido(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        //this.Plantilla(data.empresa);  
        pdf.addImage("images/empresas/"+data.empresa.id+".jpg", 'JPEG', 10, 5, 30, 25);

        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(100, 11, data.empresa.nombre, 'center');
        pdf.text(100, 14, 'RIF '+data.empresa.code+data.empresa.rif, 'center');
        pdf.text(100, 17, data.empresa.direccion, 'center');
        pdf.text(100, 20, 'Tlf '+data.empresa.tlflocal, 'center');
        //pdf.text(100, 23, 'Correo: '+empresa.correo, 'center');
       
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'ORDEN DE PEDIDO', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");
        pdf.rect(162, 13, 38, 10); 
        pdf.text('Nº', 165, 20);
        pdf.text(data.id.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);
        pdf.text('Fecha: '+data.fechad, 160, 10);
        pdf.rect(10, 30, 150, 23); 
        pdf.text('Nombre o Razón Social: '+data.cliente.nombre, 12, 35);
        pdf.text('Rif: '+data.cliente.code+data.cliente.rif, 120, 35);
        //pdf.text('Direccón: '+data.cliente.ciudad+'. '+data.cliente.direccion, 12, 40);
        pdf.text('Direccón: '+data.cliente.ciudad+'. '+data.cliente.direccion, 12, 40, {maxWidth: 145, align: "justify"});
        pdf.text('Correo: '+data.cliente.correo, 12, 50);
        pdf.text('Tlf: '+data.cliente.tlflocal, 90, 50);
        pdf.text('Celular: '+data.cliente.celular, 120, 50);

        pdf.rect(162, 30, 38, 23); 
        pdf.setFontSize(8);
        pdf.text('TOTAL:', 165, 35);

        pdf.setFont("italic", "bold");
        pdf.setFontSize(16);
        pdf.text('$'+this.moneda(data.monto), 175, 45);
        
        var produc = data.productos;
        const head = [['Nº','Código', 'Descripción', 'Cant','Costo','Descuento','Monto']];
        var body = [];
  
        for (var i = 0; i < produc.length; i++) {
			body.push([
                i+1,
                produc[i].id.toString().padStart(6,0),
                produc[i].code+' '+produc[i].name+' - '+produc[i].marca,
                produc[i].cantidad,
                '$'+this.moneda(parseFloat(produc[i].monto)+parseFloat(produc[i].descuento)),
                '$'+this.moneda(produc[i].descuento),
                '$'+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].cantidad))
            ]);
        }
        this.TablaFact(10,55,head,body);     

        pdf.setFontSize(12);
        pdf.text('Venta por: '+data.tipoventa, 10, 130);
        
        this.Out('Orden'+data.id);  
        pdf = new jsPDF();              
    }

    Servicio(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        //this.Plantilla(data.empresa);  
        pdf.addImage("images/empresas/"+data.empresa.id+".jpg", 'JPEG', 10, 5, 30, 25);

        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(100, 11, data.empresa.nombre, 'center');
        pdf.text(100, 14, 'RIF '+data.empresa.code+data.empresa.rif, 'center');
        pdf.text(100, 17, data.empresa.direccion, 'center');
        pdf.text(100, 20, 'Tlf '+data.empresa.tlflocal, 'center');
        //pdf.text(100, 23, 'Correo: '+empresa.correo, 'center');
       
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'SERVICIO', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");
        pdf.rect(162, 13, 38, 10); 
        pdf.text('Nº', 165, 20);
        pdf.text(data.id.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);
        pdf.text('Fecha: '+data.fechad, 160, 10);
        pdf.rect(10, 30, 150, 23); 
        pdf.text('Nombre o Razón Social: '+data.cliente.nombre, 12, 35);
        pdf.text('Rif: '+data.cliente.code+data.cliente.rif, 120, 35);
        //pdf.text('Direccón: '+data.cliente.ciudad+'. '+data.cliente.direccion, 12, 40);
        pdf.text('Direccón: '+data.cliente.ciudad+'. '+data.cliente.direccion, 12, 40, {maxWidth: 145, align: "justify"});
        pdf.text('Correo: '+data.cliente.correo, 12, 50);
        pdf.text('Tlf: '+data.cliente.tlflocal, 90, 50);
        pdf.text('Celular: '+data.cliente.celular, 120, 50);

        pdf.rect(162, 30, 38, 23); 
        pdf.setFontSize(8);
        pdf.text('TOTAL:', 165, 35);

        pdf.setFont("italic", "bold");
        pdf.setFontSize(16);
        pdf.text('$'+this.moneda(data.monto), 175, 45);
        
        var produc = data.productos;
        const head = [['Nº','Código', 'Descripción', 'Cant','Costo','Descuento','Monto']];
        var body = [];
  
        for (var i = 0; i < produc.length; i++) {
			body.push([
                i+1,
                produc[i].id.toString().padStart(6,0),
                produc[i].code+' '+produc[i].name,
                produc[i].cantidad,
                '$'+this.moneda(parseFloat(produc[i].monto)+parseFloat(produc[i].descuento)),
                '$'+this.moneda(produc[i].descuento),
                '$'+this.moneda(parseFloat(produc[i].monto)*parseFloat(produc[i].cantidad))
            ]);
        }
        this.TablaFact(10,55,head,body);     

        pdf.setFontSize(12);
        pdf.text('Venta por: '+data.tipoventa, 10, 130);
        
        this.Out('Servicio'+data.id);  
        pdf = new jsPDF();              
    }


    CPagar(empresa,data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        for (var i = 0; i < data.length; i++) {
            
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+data[i].empresa+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.rect(10, 10, 190, 125); 
            
            pdf.text(100, 15, empresa.nombre, 'center');
            pdf.text(100, 20, 'RIF '+empresa.code+'-'+empresa.rif, 'center');
            pdf.setFontSize(8);
            pdf.text(100, 23, empresa.direccion, 'center');
            pdf.text(100, 26, 'Tlf '+empresa.tlflocal, 'center');
            pdf.setFontSize(14);
            pdf.text(100, 35, 'CUENTA POR PAGAR', 'center');
            pdf.rect(160, 10, 40, 15); 
            pdf.text('Nº', 163, 20);
            pdf.text(data[i].control, 168, 20);

            pdf.setFontSize(10);
            pdf.text('EMPRESA/PARTICULAR: ', 15, 45);
            pdf.text(data[i].prov_nombre, 60, 45);
            pdf.text('FECHA: ', 160, 45);
            pdf.text(data[i].fechad, 175, 45);
            pdf.text('RIF: ', 15, 50);
            pdf.text(data[i].prov_rif, 25, 50);
            pdf.text('Tipo: ', 60, 50);
            pdf.text(data[i].prov_tipo, 70, 50);
            pdf.text('N° DOC: ', 160, 50);
            pdf.text(data[i].numero, 175, 50);

            pdf.setFontSize(10);
            if(data[i].factura){
                pdf.text('FACTURA', 160, 30, {maxWidth: 175, align: "left"});
            } else {
                pdf.text('NOTA DE ENTREGA', 160, 30, {maxWidth: 175, align: "left"});
            }

            const head = [['DESCRIPCIÓN']];
            var body = [[data[i].descripcion]];
            this.TablaOrd(15,55,head,body);     

            const head1 = [['OBSERVACIÓN']];
            var body1 = [[data[i].observacion]];
            this.TablaOrd(15,80,head1,body1);     

            const head2 = [['ELABORADO POR','APROBADO POR']];
            var body2 = [];
            body2.push(['Cargo:','Cargo:']);
            body2.push(['Nombre:','Nombre:']);
            body2.push(['Firma:','Firma:']);
            this.TablaAprob(15,100,head2,body2);     
        
            pdf.setFontSize(10);
            pdf.text('SUBTOTAL:', 150, 100);

            pdf.text('IVA:', 150, 105);
            pdf.text('('+data[i].iva+'%)', 160, 105);
            pdf.text('RETENCIÓN:', 150, 110);
            pdf.text('DEVOLUCIÓN:', 150, 115);
            
            if(data[i].moneda){
                pdf.text('$'+this.moneda(parseFloat(data[i].monto)), 175, 100);
                pdf.text('$'+this.moneda(parseFloat(data[i].monto*data[i].iva/100)), 175, 105);
               if(data[i].retencion){pdf.text('$'+this.moneda(parseFloat(data[i].monto*data[i].iva*3/400)), 175, 110);}
               else {pdf.text('$0', 175, 110);}
               pdf.text('$'+this.moneda(parseFloat(data[i].devolucion)), 175, 115);
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs)), 175, 100);
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs*data[i].iva/100)), 175, 105);
                if(data[i].retencion){pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs*data[i].iva*3/400)), 175, 110);}
                else {pdf.text('Bs.0', 175, 110);}
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].devolucion)), 175, 115);
            }
 
            pdf.setFontSize(16);
            pdf.text('Monto:',150, 123);
            if(data[i].moneda){
                pdf.text('$'+this.moneda(parseFloat(data[i].total-data[i].devolucion)), 170, 123);
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].totalbs-data[i].devolucion)), 170, 123);
            }

            pdf.text('Estatus:',120, 130);
            if(data[i].pagado){
                pdf.text('PAGADO',150, 130);
            } else {
                pdf.text('PENDIENTE',150, 130);
            }

            if(i+1 < data.length){pdf.addPage();}
        
        }
        this.Out('Cuenta por Pagar');  
        pdf = new jsPDF();              
    }

    CCobrar(empresa,data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        for (var i = 0; i < data.length; i++) {
            
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+data[i].empresa+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.rect(10, 10, 190, 125); 
            
            pdf.text(100, 15, empresa.nombre, 'center');
            pdf.text(100, 20, 'RIF '+empresa.code+'-'+empresa.rif, 'center');
            pdf.setFontSize(8);
            pdf.text(100, 23, empresa.direccion, 'center');
            pdf.text(100, 26, 'Tlf '+empresa.tlflocal, 'center');
            pdf.setFontSize(14);
            pdf.text(100, 35, 'CUENTA POR COBRAR', 'center');
            pdf.rect(160, 10, 40, 15); 
            pdf.text('Nº', 163, 20);
            pdf.text(data[i].control, 170, 20);
            pdf.setFontSize(10);
            pdf.text('EMPRESA/PARTICULAR: ', 15, 45);
            pdf.text(data[i].prov_nombre, 60, 45);
            pdf.text('FECHA: ', 160, 45);
            pdf.text(data[i].fechad, 175, 45);
            pdf.text('RIF: ', 15, 50);
            pdf.text(data[i].prov_rif, 25, 50);
            pdf.text('N° DOC: ', 160, 50);
            pdf.text(data[i].numero, 175, 50);
            pdf.text('Tipo: ', 160, 35);
            pdf.text(data[i].prov_tipo, 170, 35);

            const head = [['DESCRIPCIÓN']];
            var body = [[data[i].descripcion]];
            this.TablaOrd(15,55,head,body);     

            const head1 = [['OBSERVACIÓN']];
            var body1 = [[data[i].observacion]];
            this.TablaOrd(15,80,head1,body1);     

            const head2 = [['ELABORADO POR','APROBADO POR']];
            var body2 = [];
            body2.push(['Cargo:','Cargo:']);
            body2.push(['Nombre:','Nombre:']);
            body2.push(['Firma:','Firma:']);
            this.TablaAprob(15,100,head2,body2);     

            if(data[i].factura){
                pdf.text('FACTURA', 160, 30, {maxWidth: 175, align: "left"});
            } else {
                pdf.text('NOTA DE ENTREGA', 160, 30, {maxWidth: 175, align: "left"});
            }

            pdf.setFontSize(10);
            pdf.text('IVA:', 150, 100);
            pdf.text('('+data[i].iva+'%)', 160, 100);
            pdf.text('RETENCIÓN:', 150, 105);
            if(data[i].moneda){
               pdf.text('$'+this.moneda(parseFloat(data[i].monto*data[i].iva/100)), 175, 100);
               if(data[i].retencion){pdf.text('$'+this.moneda(parseFloat(data[i].monto*data[i].iva*3/400)), 175, 105);}
               else {pdf.text('$0', 175, 105);}
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs*data[i].iva/100)), 175, 100);
                if(data[i].retencion){pdf.text('Bs.'+this.moneda(parseFloat(data[i].monto*data[i].iva*3/400)), 175, 105);}
                else {pdf.text('Bs.0', 175, 105);}
            }
 
            pdf.setFontSize(16);
            pdf.text('Monto:',150, 120);
            if(data[i].moneda){
                pdf.text('$'+this.moneda(parseFloat(data[i].total)), 170, 120);
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].totalbs)), 170, 120);
            }

            pdf.text('Estatus:',120, 130);
            if(data[i].pagado){
                pdf.text('PAGADO',150, 130);
            } else {
                pdf.text('PENDIENTE',150, 130);
            }

            if(i+1 < data.length){pdf.addPage();}
        
        }
        this.Out('Cuenta por Pagar');  
        pdf = new jsPDF();              
    }

    InterCamb(empresa,data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        for (var i = 0; i < data.length; i++) {
            
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+data[i].empresa+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.rect(10, 10, 190, 125); 
            
            pdf.text(100, 15, empresa.nombre, 'center');
            pdf.text(100, 20, 'RIF '+empresa.code+'-'+empresa.rif, 'center');
            pdf.setFontSize(8);
            pdf.text(100, 23, empresa.direccion, 'center');
            pdf.text(100, 26, 'Tlf '+empresa.tlflocal, 'center');
            pdf.setFontSize(14);
            pdf.text(100, 35, 'INTERCAMBIO DE MONEDA', 'center');
            pdf.rect(160, 10, 40, 15); 
            pdf.text('Nº', 163, 20);
            pdf.text(data[i].control, 170, 20);
            pdf.setFontSize(10);
            pdf.text('EMPRESA/PARTICULAR: ', 15, 45);
            pdf.text(data[i].prov_nombre, 60, 45);
            pdf.text('FECHA: ', 160, 45);
            pdf.text(data[i].fechad, 175, 45);
            pdf.text('RIF: ', 15, 50);
            pdf.text(data[i].prov_rif, 25, 50);
            
            pdf.text('Tipo: ', 150, 50);
            pdf.text(data[i].prov_tipo, 160, 50);

            const head = [['DESCRIPCIÓN']];
            var body = [[data[i].descripcion]];
            this.TablaOrd(15,55,head,body);     
           
            const head1 = [['OBSERVACIÓN']];
            var body1 = [[data[i].observacion]];
            this.TablaOrd(15,80,head1,body1);     

            const head2 = [['ELABORADO POR','APROBADO POR']];
            var body2 = [];
            body2.push(['Cargo:','Cargo:']);
            body2.push(['Nombre:','Nombre:']);
            body2.push(['Firma:','Firma:']);
            this.TablaAprob(15,100,head2,body2);     

            pdf.setFontSize(10);
            pdf.text('Tasa:', 150, 100);
            pdf.text('Bs.'+this.moneda(parseFloat(data[i].tasa)), 175, 100);

            pdf.setFontSize(16);
            pdf.text('Monto:',120, 120);
            pdf.text('>>>',155, 120);
            if(data[i].moneda){
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs)), 140, 120);
                pdf.text('$'+this.moneda(parseFloat(data[i].monto)), 170, 120);
            } else {
                pdf.text('$'+this.moneda(parseFloat(data[i].monto)), 140, 120);
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs)), 170, 120);
            }


            pdf.text('Estatus:',120, 130);
            if(data[i].pagado){
                pdf.text('PAGADO',150, 130);
            } else {
                pdf.text('PENDIENTE',150, 130);
            }
            
            if(i+1 < data.length){pdf.addPage();}
        }
        this.Out('Cuenta por Pagar');  
        pdf = new jsPDF();              
    }

    Nota(data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        //this.Plantilla(data.empresa);  
        pdf.addImage("images/empresas/"+data.empresa.id+".jpg", 'JPEG', 10, 5, 30, 25);

        pdf.setFontSize(8);
        pdf.setFont("italic", "bold");
        pdf.text(100, 11, data.emp_nombre, 'center');
        pdf.text(100, 14, 'RIF '+data.emp_rif, 'center');
        pdf.text(100, 17, data.empresa.direccion, 'center');
        pdf.text(100, 20, 'Tlf '+data.emp_tlf, 'center');

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 27, 'NOTA DE ENTREGA', 'center');
        pdf.setFontSize(14);
        pdf.setFont("italic","normal");
        pdf.rect(162, 13, 38, 10); 
        pdf.text('Nº', 165, 20);
        pdf.text(data.control.toString().padStart(8, 0), 175, 20);
        pdf.setFontSize(10);

        pdf.text('Fecha: '+data.fechad, 160, 10);
        pdf.rect(10, 30, 190, 23); 
        pdf.text('Nombre o Razón Social: '+data.cli_nombre, 12, 35);
        pdf.text('Rif: '+data.cli_rif, 160, 35);
        pdf.text('Direccón: '+data.cli_direccion, 12, 40, {maxWidth: 145, align: "justify"});
        pdf.text('Correo: '+data.cli_correo, 12, 50);
        pdf.text('Tlf: '+data.cli_tlflocal, 88, 50);
        pdf.text('Celular: '+data.cli_celular, 160, 50);
        
        var total = 0;
        var totalbs = 0;
        var produc = data.detalle;
        const head = [['Nº','Código', 'Descripción', 'Cant','Costo','Descuento','Monto']];
        var body = [];
  
        for (var i = 0; i < produc.length; i++) {
            if(data.moneda>0){
                body.push([
                    i+1,
                    produc[i].id.toString().padStart(6,0),
                    produc[i].code+' '+produc[i].name+' - '+produc[i].marca,
                    produc[i].cantidad,
                    '$ '+this.moneda(parseFloat(data.descrip[i].monto)),
                    '$ '+this.moneda(produc[i].descuento),
                    '$ '+this.moneda(parseFloat(data.descrip[i].monto)*parseFloat(produc[i].cantidad))
                ]);    
            } else {
                body.push([
                    i+1,
                    produc[i].id.toString().padStart(6,0),
                    produc[i].code+' '+produc[i].name+' - '+produc[i].marca,
                    produc[i].cantidad,
                    'Bs '+this.moneda(parseFloat(data.descrip[i].montobs)),
                    'Bs '+this.moneda(produc[i].descuento),
                    'Bs '+this.moneda(parseFloat(data.descrip[i].montobs)*parseFloat(produc[i].cantidad))
                ]);    
            }
            total = total + parseFloat(data.descrip[i].monto)*parseFloat(produc[i].cantidad);
            totalbs = totalbs + parseFloat(data.descrip[i].montobs)*parseFloat(produc[i].cantidad);
        }
        this.TablaFact(10,55,head,body);     


        pdf.setFontSize(12);
        pdf.text('Venta por: '+data.vendedor, 10, 130);
        pdf.text('TOTAL:', 145, 135);
        pdf.setFontSize(16);
        if(data.moneda>0){
            pdf.text('$. '+this.moneda(total), 165, 135);
        } else {
            pdf.text('Bs. '+this.moneda(totalbs), 165, 135);
        }
        this.Out('Nota_'+data.id);  
        pdf = new jsPDF();              
    }

    OrdenPago(empresa,data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
            
            pdf.setFontSize(9);
            pdf.rect(145, 5, 55, 7); 
            pdf.text('Nº DE CONTROL '+data.control, 150, 10);
            pdf.text('TASA DEL DÍA: '+data.tasa, 12, 18);
            pdf.rect(10, 20, 135, 7); 
            pdf.setFont("italic", "bold");    
            pdf.setFontSize(12);
            pdf.text('ORDEN DE PAGO', 60, 25);
            pdf.rect(145, 20, 55, 7); 
            pdf.setFont("italic", "");    
            pdf.setFontSize(9);
            pdf.text('FECHA: '+data.fechad, 155, 25);

            pdf.rect(10, 30, 190, 17); 
            pdf.text('EMPRESA OTORGANTE: '+empresa.nombre, 12, 35);
            pdf.text('RIF: '+empresa.code+'-'+empresa.rif, 12, 40);
            pdf.text('DOMICILIO FISCAL: '+empresa.direccion, 12, 45, {maxWidth: 185, align: "left"});

            pdf.rect(10, 50, 190, 24); 
            pdf.text('BENEFICIARIO: '+data.prov_nombre, 12, 55);
            pdf.text('RIF: '+data.prov_rif, 12, 60);
            pdf.text('DOMICILIO FISCAL: '+data.prov_direccion, 12, 65, {maxWidth: 175, align: "left"});
            pdf.text('TELEFONOS: '+data.prov_tlflocal, 12, 73);

            pdf.rect(10, 77, 135, 4); 
            pdf.text('DESCRIPCIÓN', 70, 80)
            pdf.rect(145, 77, 55, 4); 
            pdf.text('MONTO', 165, 80)
            pdf.rect(10, 81, 135, 10); 
            pdf.setFontSize(7);
            pdf.text(data.descripcion, 12, 85, {maxWidth: 130, align: "left"});
            pdf.rect(145, 81, 55, 10); 
            pdf.setFontSize(9);
            if(data.moneda){
                pdf.text('$'+this.moneda(parseFloat(data.total)), 165, 86);
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data.totalbs)), 165, 86);
            }

            pdf.rect(10, 95, 190, 12); 
            pdf.text('CONCEPTO DE PAGO', 12, 98)
            pdf.setFontSize(7);
            pdf.text(data.observacion, 12, 103, {maxWidth: 180, align: "left"});

        const head2 = [['ELABORADO POR','APROBADO POR']];
        var body2 = [];
        body2.push(['Cargo:','Cargo:']);
        body2.push(['Nombre:','Nombre:']);
        body2.push(['Firma:','Firma:']);
        this.TablaAprob2(10,110,head2,body2);     

        this.Out('Orden de Pago');  
        pdf = new jsPDF();              
    }

    Recibo(fecha,data,detallepago,caso) {
        pdf = new jsPDF('p', 'mm', [210, 290]);
            var monto = 0;
            var montobs = 0;
            var montofinal = 0;
            var factor = 1;
            if(!caso){factor = -1;}

            for (var i = 0; i < detallepago.length; i++) {
                if(detallepago[i].print){
                    monto = parseFloat(detallepago[i].monto) + parseFloat(monto);
                    montobs = parseFloat(detallepago[i].montobs) + parseFloat(montobs);    
                }
            }
            if(caso) {monto = (-1)*parseFloat(monto);}

            pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+data.empresa+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.addImage("images/empresas/"+data.empresa+".jpg", 'JPEG', 11, 148, 40, 33);           

            pdf.rect(10, 10, 190, 125); 
            pdf.rect(10, 150, 190, 125); 

            pdf.setFontSize(18);
            pdf.setFont("italic", "bold");

            pdf.text(100, 20, 'RECIBO', 'center');
            pdf.text(100, 160, 'RECIBO', 'center');

            pdf.setFontSize(12);
            pdf.text('Fecha: '+fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'), 150, 25);
            pdf.text('Fecha: '+fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'), 150, 165);

            pdf.setFontSize(16);
            if(data.moneda){
                montofinal = (factor)*parseFloat(monto);
                pdf.text('Monto $ '+this.moneda((factor)*parseFloat(monto)), 150, 35);
                pdf.text('Monto $ '+this.moneda((factor)*parseFloat(monto)), 150, 175);    
            } else {
                montofinal = (factor)*parseFloat(montobs);
                pdf.text('Monto Bs '+this.moneda((factor)*parseFloat(montobs)), 150, 35);
                pdf.text('Monto Bs '+this.moneda((factor)*parseFloat(montobs)), 150, 175);    
            }

            pdf.setFontSize(10);
            pdf.setFont("italic", "");

            var pago = 'He recibido de '+data.emp_nombre+', la cantidad de: '+Num_Letra(montofinal,data.moneda)+', por concepto de: '+data.descripcion;

            pdf.text(pago, 15, 45, {maxWidth: 180, align: "justify"});
            pdf.text(pago, 15, 185, {maxWidth: 180, align: "justify"});
           
            const head = [['Fecha', 'Forma de Pago', 'Referencia','Monto $','Monto Bs']];
            var body = [];
            var dolar = null;
            var bs = null;
            for (var j = 0; j < detallepago.length; j++) {
                dolar = null;
                bs = null;
                if(detallepago[j].moneda){
                    dolar = '$'+this.moneda(parseFloat((factor)*detallepago[j].monto));
                } else {
                    bs = 'Bs'+this.moneda(parseFloat((factor)*detallepago[j].montobs));
                }
                if(detallepago[j].print){
                    body.push([
                        detallepago[j].fechad,
                        detallepago[j].tipo_nombre,
                        detallepago[j].referencia,
                        dolar,
                        bs,
                    ]);    
                }
            }
            this.TablaFact(30,60,head,body);     
            this.TablaFact(30,200,head,body);     
    
            pdf.text(50, 110, 'RECIBÍ CONFORME', 'center');
            pdf.text(50, 250, 'RECIBÍ CONFORME', 'center');

            pdf.setFontSize(8);

            pdf.text(100, 112, '__________________________', 'center');
            pdf.text(98, 116, 'NOMBRE Y APELLIDO', 'center');
            pdf.text(100, 120, 'CI ____________________', 'center');
            pdf.text(100, 128, '__________________________', 'center');
            pdf.text(100, 133, 'FIRMA', 'center');

            pdf.text(100, 252, '__________________________', 'center');
            pdf.text(98, 256, 'NOMBRE Y APELLIDO', 'center');
            pdf.text(100, 260, 'CI ____________________', 'center');
            pdf.text(100, 268, '__________________________', 'center');
            pdf.text(100, 273, 'FIRMA', 'center');

        this.Out('Recibo');  
        pdf = new jsPDF();              
    }

    OrdenPed(fecha,data,empresa) {
        pdf = new jsPDF('p', 'mm', [210, 290]);

        pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+empresa.id+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.addImage("images/empresas/"+empresa.id+".jpg", 'JPEG', 11, 148, 40, 33);           
            pdf.rect(10, 10, 190, 125); 
            pdf.rect(10, 150, 190, 125); 

            pdf.setFontSize(10);
            pdf.setFont("italic", "bold");
            pdf.text('Fecha: '+fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'), 150, 20);
            pdf.text('Fecha: '+fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'), 150, 160);
            pdf.text('Nº '+data.control, 150, 25);
            pdf.text('Nº '+data.control, 150, 165);
            pdf.setFontSize(14);
            pdf.text(100, 30, 'ORDEN DE PEDIDO', 'center');
            pdf.text(100, 170, 'ORDEN DE PEDIDO', 'center');

            pdf.setFontSize(8);
            pdf.rect(15, 40, 180, 22); 
            pdf.rect(15, 180, 180, 22); 
            pdf.text('EMPRESA: '+data.proveedor.nombre, 20, 45);
            pdf.text('EMPRESA: '+data.proveedor.nombre, 20, 185);
            pdf.text('RIF: '+data.proveedor.code+'-'+data.proveedor.rif, 20, 50);
            pdf.text('RIF: '+data.proveedor.code+'-'+data.proveedor.rif, 20, 190);
            pdf.text('DOMICILIO FISCAL: '+data.proveedor.direccion, 20, 55, {maxWidth: 175, align: "left"});
            pdf.text('DOMICILIO FISCAL: '+data.proveedor.direccion, 20, 195, {maxWidth: 175, align: "left"});

            const head = [['Nº', 'Código', 'Descripción','Precio x Unid','Cantidad','Monto']];
            var body = [];
            for (var j = 0; j < data.detalle.length; j++) {
                body.push([
                    j+1,
                    data.detalle[j].code,
                    data.detalle[j].name,
                    data.detalle[j].monto,
                    data.detalle[j].cantidad,
                    data.detalle[j].monto,
                ]);    
            }
            this.TablaFact(20,65,head,body);     
            this.TablaFact(20,205,head,body);     


            pdf.setFontSize(10);
            pdf.setFont("italic", "");
   
            pdf.text(30, 120, 'RECIBÍ CONFORME', 'center');
            pdf.text(30, 260, 'RECIBÍ CONFORME', 'center');

            pdf.setFontSize(8);

            pdf.text(80, 112, '__________________________', 'center');
            pdf.text(78, 116, 'NOMBRE Y APELLIDO', 'center');
            pdf.text(80, 125, '__________________________', 'center');
            pdf.text(80, 130, 'FIRMA', 'center');

            pdf.text(80, 252, '__________________________', 'center');
            pdf.text(78, 256, 'NOMBRE Y APELLIDO', 'center');
            pdf.text(80, 265, '__________________________', 'center');
            pdf.text(80, 270, 'FIRMA', 'center');
            pdf.setFontSize(12);
            if(data.retencion){
                if(data.moneda){
                    pdf.text('Retención: '+this.moneda(parseFloat(data.monto*(3*data.iva/400))),130, 100);
                    pdf.text('Retención: '+this.moneda(parseFloat(data.monto*(3*data.iva/400))),130, 240);
                } else {
                    pdf.text('Retención: '+this.moneda(parseFloat(data.montobs*(3*data.iva/400))),130, 100);
                    pdf.text('Retención: '+this.moneda(parseFloat(data.montobs*(3*data.iva/400))),130, 240);
                }    
            }

            pdf.setFontSize(16);
            pdf.text('Monto a Pagar:',130, 130);
            pdf.text('Monto a Pagar:',130, 280);
            if(data.moneda){
                if(data.retencion){
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/400))), 170, 120);
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/400))), 170, 260);
                } else {
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/100))), 170, 120);
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/100))), 170, 260);
                }
            } else {
                if(data.retencion){
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/400))), 170, 120);
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/400))), 170, 260);
                } else {
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/100))), 170, 120);
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/100))), 170, 260);                        
                }
            }

        this.Out('Orden Pedido');  
        pdf = new jsPDF();              
    }

    Presupuesto(fecha,data,empresa) {
        pdf = new jsPDF('p', 'mm', [210, 290]);

        pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+empresa.id+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.rect(10, 10, 190, 125); 

            pdf.setFontSize(10);
            pdf.setFont("italic", "bold");
            pdf.text('Fecha: '+fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'), 150, 20);
            pdf.text('Nº '+data.control, 150, 25);
            pdf.setFontSize(14);
            pdf.text(100, 30, 'PRESUPUESTO', 'center');

            pdf.setFontSize(8);
            pdf.rect(15, 40, 180, 22); 
            pdf.text('EMPRESA: '+data.cliente.nombre, 20, 45);
            pdf.text('RIF: '+data.cliente.code+'-'+data.cliente.rif, 20, 50);
            pdf.text('DOMICILIO FISCAL: '+data.cliente.direccion, 20, 55, {maxWidth: 175, align: "left"});

            const head = [['Nº', 'Código', 'Descripción','Precio x Unid','Cantidad','Monto']];
            var body = [];
            for (var j = 0; j < data.detalle.length; j++) {
                body.push([
                    j+1,
                    data.detalle[j].code,
                    data.detalle[j].name,
                    data.detalle[j].monto,
                    data.detalle[j].cantidad,
                    data.detalle[j].monto,
                ]);    
            }
            this.TablaFact(20,65,head,body);     


            pdf.setFontSize(10);
            pdf.setFont("italic", "");
   
            pdf.text(30, 120, 'RECIBÍ CONFORME', 'center');

            pdf.setFontSize(8);

            pdf.text(80, 112, '__________________________', 'center');
            pdf.text(78, 116, 'NOMBRE Y APELLIDO', 'center');
            pdf.text(80, 125, '__________________________', 'center');
            pdf.text(80, 130, 'FIRMA', 'center');

            pdf.setFontSize(12);
            if(data.retencion){
                if(data.moneda){
                    pdf.text('Retención: '+this.moneda(parseFloat(data.monto*(3*data.iva/400))),130, 100);
                } else {
                    pdf.text('Retención: '+this.moneda(parseFloat(data.montobs*(3*data.iva/400))),130, 100);
                }    
            }

            pdf.setFontSize(16);
            pdf.text('Monto a Pagar:',130, 130);
            if(data.moneda){
                if(data.retencion){
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/400))), 170, 120);
                } else {
                    pdf.text('$'+this.moneda(parseFloat(data.monto*(1+data.iva/100))), 170, 120);
                }
            } else {
                if(data.retencion){
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/400))), 170, 120);
                } else {
                    pdf.text('Bs.'+this.moneda(parseFloat(data.montobs*(1+data.iva/100))), 170, 120);
                }
            }

        this.Out('Presupuesto');  
        pdf = new jsPDF();              
    }

    CAporte(empresa,data) {
        pdf = new jsPDF('l', 'mm', [210, 145]);
        for (var i = 0; i < data.length; i++) {
            
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");    
            pdf.addImage("images/empresas/"+data[i].empresa+".jpg", 'JPEG', 11, 8, 40, 33);           
            pdf.rect(10, 10, 190, 125); 
            pdf.text(100, 15, empresa.nombre, 'center');
            pdf.text(100, 20, 'RIF '+empresa.code+'-'+empresa.rif, 'center');
            pdf.setFontSize(8);
            pdf.text(100, 23, empresa.direccion, 'center');
            pdf.text(100, 26, 'Tlf '+empresa.tlflocal, 'center');
            pdf.setFontSize(14);
            pdf.text(100, 35, 'APORTE', 'center');
            pdf.rect(160, 10, 40, 15); 
            pdf.text('Nº', 163, 20);
            pdf.text(data[i].control, 168, 20);

            pdf.setFontSize(10);
            pdf.text('SOCIO: ', 15, 45);
            pdf.text(data[i].prov_nombre, 30, 45);
            pdf.text('RIF: ', 125, 45);
            pdf.text(data[i].prov_rif, 135, 45);
            pdf.text('FECHA: ', 160, 45);
            pdf.text(data[i].fechad, 175, 45);

            pdf.setFontSize(10);
            const head = [['DESCRIPCIÓN']];
            var body = [[data[i].descripcion]];
            this.TablaOrd(15,55,head,body);     

            const head1 = [['OBSERVACIÓN']];
            var body1 = [[data[i].observacion]];
            this.TablaOrd(15,80,head1,body1);     

            const head2 = [['ELABORADO POR','APROBADO POR']];
            var body2 = [];
            body2.push(['Cargo:','Cargo:']);
            body2.push(['Nombre:','Nombre:']);
            body2.push(['Firma:','Firma:']);
            this.TablaAprob(15,100,head2,body2);     

            pdf.setFontSize(16);
            pdf.text('Monto:',150, 120);
            if(data[i].moneda){
                pdf.text('$'+this.moneda(parseFloat(data[i].monto)), 170, 120);
            } else {
                pdf.text('Bs.'+this.moneda(parseFloat(data[i].montobs)), 170, 120);
            }

            pdf.text('Estatus:',120, 130);
            if(data[i].pagado){
                pdf.text('PAGADO',150, 130);
            } else {
                pdf.text('PENDIENTE',150, 130);
            }

            if(i+1 < data.length){pdf.addPage();}
        
        }
        this.Out('Cuenta por Pagar');  
        pdf = new jsPDF();              
    }






}


export default PDFs;



//export default exports;