<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Iniciar Pedido" icon="pi pi-thumbs-up" class="p-button-secondary p-mr-2 p-mb*2" @click="openNew" v-if="$store.state.carrito"/>
					</template>
					<template v-slot:right>
                        <h4>Monto a Cancelar: ${{formatCurrency(parseFloat(monto).toFixed(2))}}</h4><br>
                        Bs.{{moneda(parseFloat(monto*$store.state.tasa).toFixed(2))}}			
                    </template>
				</Toolbar>
				<DataTable ref="dt" :value="$store.state.carrito" class="p-datatable-responsive-demo" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Pág {first} a {last} de {totalRecords} productos">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Productos a Comprar</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Imagen">
						<template #body="slotProps">
							<span class="p-column-title">Image</span>
							<img :src="'images/productos/'+ slotProps.data.imagen" :alt="slotProps.data.image" class="product-image" />
						</template>
					</Column>
					<Column field="name" header="Nombre" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}<br>
                            {{slotProps.data.vehiculo}}
						</template>
					</Column>
					<Column header="Precio" :sortable="true">
						<template #body="slotProps">
							Bs.{{moneda(parseFloat(slotProps.data.preciototal*$store.state.tasa).toFixed(2))}}
							<br>
							${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}
						</template>
					</Column>
					<Column field="marca" header="Marca de Producto" :sortable="true">
						<template #body="slotProps">
							<img :src="'images/marcas/'+ slotProps.data.marc +'.png'" :alt="slotProps.data.marca" class="marca-image" v-if="slotProps.data.marc>0"/>
						</template>
					</Column>
					<Column field="name" header="Cantidad" :sortable="true">
						<template #body="slotProps" style="width:10px;" >
							<span class="p-column-title">Cantidad</span>
                            {{slotProps.data.cantidad}}<br>
						</template>
					</Column>
					<Column>
						<template #body="slotProps" style="width:10px;" >
                            <Button icon="pi pi-minus" class="p-button-rounded p-button-warning" @click="Bajar(slotProps.data)" />
                            <Button icon="pi pi-plus" class="p-button-rounded p-button-info" @click="Subir(slotProps.data)" />
						</template>
					</Column>
					<Column header="SubTotal" :sortable="true">
						<template #body="slotProps">
							Bs.{{moneda(parseFloat(slotProps.data.preciototal*$store.state.tasa*slotProps.data.cantidad).toFixed(2))}}
							<br>
							${{formatCurrency(parseFloat(slotProps.data.preciototal*slotProps.data.cantidad).toFixed(2))}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps"> 
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="DeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
import API from "../service/API";

export default {
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			size: '60vw',
			categorias: null,
			categselec: null,
			marcas: null,
			vehiculos: null,
			empresas: null,
			modelos: null,
			seleccion: null,
			monto: 0
		}
	},
	productService: null,
	created() {
		if (this.$store.state.mobile){this.size = '90vw';}
		this.Monto();
	},
	mounted() {
	},
	methods: {
		Mostrar() {
			const Consulta = new API('Inventario');
			Consulta.Ini('Productos').then(response => {
				//this.$store.state.error = result;
				this.insert = response.insert;
				this.edit = response.edit;
				this.del = response.delete;
				this.categorias=response.consult.sucategoria;
				this.marcas=response.consult.marcas;
				this.vehiculos=response.consult.vehiculos;
				this.empresas=response.consult.empresas;
				this.modelos=response.consult.modelos;
				this.$store.commit('Loading');
				}); 
			},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		DeleteProduct(product) {
            this.$store.commit('DelCarrito',product);
            this.Monto();
		},
		Subir(product) {
            if (product.cantidad<product.disponibles){
                product.cantidad++;
                this.$store.commit('ActCarrito');
                this.Monto();
            }
		},
		Bajar(product) {
            if (product.cantidad>1){
                product.cantidad--;
                this.$store.commit('ActCarrito');
                this.Monto();
            }
		},
		Monto() {
            this.monto = 0;
            this.$store.state.carrito.forEach(element => {
                //parseFloat(monto*$store.state.tasa);
                this.monto = this.monto + (element.preciototal*element.cantidad);
            });
		},
			moneda(bs) {
				return bs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 50px;
}


.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
