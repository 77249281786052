<template>
	<div class="p-grid">
		<!--
		<div class="p-col-12 p-lg-12" style="padding: 0rem 0rem 0rem 0rem;" v-if="$store.state.authenticated">
			<img style="width: 100%;" alt="logo" src="images/banner.jpg">
		</div>
	-->
		<br>
		<div class="p-col-12 p-md-12 p-grid" style="background-color: #ffffff;" v-if="$store.state.authenticated">
			<div class="p-col-12 p-md-3" v-for="(stats, index) in $store.state.categoria" :key="index">		
				<div class="card" style="background-color: #ec990d;"> 
					<Card @click="IrCategoria(stats)">
						<template #title>
							<h6>{{stats.nombre}}</h6>
							<i :class="stats.icon" style="color: #d46900;fontSize: 1.5rem"> {{stats.cant}}</i>
						</template>
						<template #content>
							<img style="width: 100%;" :src="'images/categorias/'+stats.id+'.'+stats.img">
						</template>
					</Card>
				</div>
			</div>
				
		</div>
		
		<!--		
		<div class="p-col-12 p-md-12" >
			
			<Ca<CarouselVehiculos />rouselProductos />
		</div>
		<div class="p-col-12 p-md-12">
			<CarouselMarcas />
		</div>
		-->

	</div>
</template>

<script>
	//import CarouselProductos from '@/components/CarouselProductos.vue';
	//import CarouselMarcas from '@/components/CarouselMarcas.vue';
	//import CarouselVehiculos from '@/components/CarouselVehiculos.vue';

	export default {
		components: {
			//'CarouselProductos': CarouselProductos,
			//'CarouselMarcas': CarouselMarcas,
			//'CarouselVehiculos': CarouselVehiculos,			
		},
		data() {
			return {
			}
		},
		methods: {
			IrCategoria(stats){
				if (stats.cant>0){
					this.$router.push({ path: '/catalago/categoria/'+stats.id});
				}
			}
		},
			
	}
</script>

<style scoped lang="scss">
	p {
		line-height: 1.5;
		margin: 0;
	}

	::v-deep(.p-card .p-card-title) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
	}
</style>
