<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<div class="p-col-12 p-lg-12 dashboard">
					<div class="p-grid">
						<div class="p-col-8">
							<div class="card summary">
								<img style="height: 50px;" :src="imagen" :alt="titulo" v-if="imagen"/>
								<span class="title">{{titulo}}</span>
								<span class="detail">{{subtitulo}}</span>
								<span class="count visitors">{{cantidad}}</span>
							</div>
						</div>
						<div class="p-col-4">
							<div class="card summary">
								<span class="title">
									<div class="p-col-12">
										<label for="name">Buscar: </label>
										<InputText id="code" v-model="buscar" @keyup="Buscar()" style="width: 350px;"/>
									</div>
								</span>
							</div>
						</div>
					</div>

				</div>
				
				<DataView :value="productos" :layout="layout" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField">
					<template #header>
						<div class="p-grid p-nogutter">
							<div class="p-col-6" style="text-align: left">
								<Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Ordenar por ..." @change="onSortChange($event)"/>
							</div>
							<div class="p-col-6" style="text-align: right">
								<DataViewLayoutOptions v-model="layout" />
							</div>
						</div>
					</template>

					<template #list="slotProps">
						<div class="p-col-12">
							<div class="product-list-item">
								<img class="product-image" :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg" :alt="slotProps.data.name"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{slotProps.data.name}}</div>
									<div class="product-name" style="font-size: 16px;">{{slotProps.data.tipovehiculo}} {{slotProps.data.vehiculo}}</div>
									<div class="product-description">
										{{slotProps.data.code}}<br>
										Disponibles: {{slotProps.data.disponibles}}
									</div>
									<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{slotProps.data.category}}
										<strong style="color: orange;"> {{slotProps.data.marca}}</strong>
									</span>
									<div>
										<img  class="marca-image" :src="'images/marcas/'+slotProps.data.marc+'.png'" :alt="slotProps.data.marca" v-if="slotProps.data.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+slotProps.data.veh+'.png'" :alt="slotProps.data.tipovehiculo" v-if="slotProps.data.veh>0"/>
									</div>
								</div>
								<div class="product-list-action">
									<span class="product-price">${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</span>
									<Button class="p-button-warning" icon="pi pi-shopping-cart" label="Agregar al Carrito" :disabled="slotProps.data.stock === 'OUTOFSTOCK'" @click="confirm(slotProps.data)"></Button>
									<Button class="p-button-secondary" icon="pi pi-search-plus" label="Ver Descripción" @click="open(slotProps.data)"></Button>
									<span :class="'product-badge status-'+slotProps.data.stock.toLowerCase()">{{slotProps.data.stock}}</span>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="p-col-12 p-md-4">
							<div class="product-grid-item card">
								<div class="product-grid-item-top">
									<div>
										<i class="pi pi-tag product-category-icon"></i>
										<span class="product-category" >
											{{slotProps.data.category}}
											<strong style="color: orange;"> {{slotProps.data.marca}}</strong>
										</span>
									</div>
									<span :class="'product-badge status-'+slotProps.data.stock.toLowerCase()">{{slotProps.data.stock}}</span>
								</div>
								<div class="product-grid-item-top">
									<div>
										<img  class="marca-image" :src="'images/marcas/'+slotProps.data.marc+'.png'" :alt="slotProps.data.marca" v-if="slotProps.data.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+slotProps.data.veh+'.png'" :alt="slotProps.data.tipovehiculo" v-if="slotProps.data.veh>0"/>
									</div>
								</div>
								<div class="product-grid-item-content">
									<img  class="product-image" :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg" :alt="slotProps.data.name"/>
									<div class="product-name" style="font-size: 15px;">{{slotProps.data.name}}</div>
									<div class="product-name" style="font-size: 15px;">{{slotProps.data.tipovehiculo}} {{slotProps.data.vehiculo}}</div>
									<div class="product-description">
										{{slotProps.data.code}}<br>
										Disponibles: {{slotProps.data.disponibles}}
									</div>
									<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
								</div>
								<div class="product-grid-item-bottom">
									<span class="product-price">${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</span>
									<Button class="p-button-warning" icon="pi pi-shopping-cart" :disabled="slotProps.data.stock === 'OUTOFSTOCK'" @click="confirm(slotProps.data)"></Button>
									<Button class="p-button-secondary" icon="pi pi-search-plus" @click="open(slotProps.data)"></Button>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
				<Dialog :header="detalles.name" v-model:visible="display" :style="{width: size}" :modal="true" :maximizable="true">
							<Toast/>
							<div class="product-list-item">
								<img class="product-image" :src="$store.state.url_api+'upload_prod/'+detalles.imagen+'?version='+detalles.fecha_reg" :alt="detalles.name"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{detalles.tipovehiculo}} {{detalles.vehiculo}}</div>
                                    <div class="product-description">Código: {{detalles.code}}</div>
									<div class="product-description">{{detalles.description}}</div>
									<Rating :modelValue="detalles.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{detalles.category}}
										<strong style="color: orange;"> {{detalles.marca}}</strong>
									</span>
									<div>
										<img  class="marca-image" :src="'images/marcas/'+detalles.marc+'.png'" :alt="detalles.marca" v-if="detalles.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+detalles.veh+'.png'" :alt="detalles.tipovehiculo" v-if="detalles.veh>0"/>
									</div>
								</div>
							</div>
					<template #footer>
					<Button class="p-button-warning" icon="pi pi-shopping-cart" label="Agregar al Carrito" :disabled="detalles.stock === 'OUTOFSTOCK'" @click="confirm(detalles)"></Button>

						<Button label="Cerrar" @click="close" icon="pi pi-times-circle" class="p-button-danger"/>
					</template>
				</Dialog>
				<Dialog header="Confirmar Agregar al Carrito" v-model:visible="confiProducto" :style="{width: size}" :modal="true">
					<Toast/>
					<br>
							<div class="product-list-item">
								<img :src="$store.state.url_api+'upload_prod/'+detalles.imagen+'?version='+detalles.fecha_reg" :alt="detalles.name" class="product-image"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{detalles.tipovehiculo}} {{detalles.vehiculo}}</div>
									<div class="product-description">{{detalles.description}}</div>
									<Rating :modelValue="detalles.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{detalles.category}}
										<strong style="color: orange;"> {{detalles.marca}}</strong>
									</span>
								</div>
									<img :src="'images/marcas/'+detalles.marc + '.png'" :alt="detalles.marca" class="marca-image" v-if="detalles.marc>0"/>
									<img :src="'images/vehiculos/'+detalles.veh + '.png'" :alt="detalles.tipovehiculo" class="marca-image" v-if="detalles.veh>0"/>
							</div>
					<div class="confirmation-content">
						<i class="pi pi-shopping-cart p-mr-3" style="font-size: 2rem" />
						<span>Indique la cantidad a camprar: </span>
						<InputNumber v-model="detalles.cantidad" min="1" :max="detalles.disponibles" showButtons mode="decimal"></InputNumber>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="AgreCarrito()"/>
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="confiProducto = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="mensaje" :style="{width: size}" header="Mensaje" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span >Para incluir un producto debe logerse primero!</span>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="mensaje = false"/>
					</template>
				</Dialog>


	</div>
</template>

<script>
	import API from "../service/API";

	export default {
		data() {
			return {
                titulo: null,
                subtitulo: null,
                productos: [],
                resultado: [],
                display: false,
                detalles: {},
				dataviewValue: null,
				layout: 'grid',
				sortKey: null,
				sortOrder: null,
				sortField: null,
				sortOptions: [
					{label: 'Precio de mayor a menor', value: '!preciototal'},
					{label: 'Precio de menor a mayor', value: 'preciototal'},
					{label: 'Nombre', value: 'name'},
					{label: 'Vehículo', value: 'tipovehiculo'},
					{label: 'Marca', value: 'marca'},
                ],
				size: '60vw',
				imagen: null,
				cantidad: 0,
				confiProducto: false,
				mensaje: false,
				buscar: null,

			}
        },
            created() {
				this.url = this.$store.state.url;
				if (this.$store.state.mobile){this.size = '90vw';}
                const Consulta = new API('Productos');
                if (this.$route.params.cond =='categoria') {
                    Consulta.Procesar('ProdctCategoria',{
						id: this.$route.params.id
					}).then(response => {
						//this.$store.state.error = response;
						this.titulo = response.consult.titulo;
						this.subtitulo = response.consult.subtitulo;
						this.cantidad = response.consult.cantidad;
						this.imagen = 'images/categorias/'+this.$route.params.id+'.jpg';
						this.productos = response.consult.productos;
						this.resultado = response.consult.productos;
						this.$store.commit('Loading'); 
                    });  
                }
                if (this.$route.params.cond =='subcategoria') {
                    Consulta.Procesar('ProdctSubCategoria',{
						id: this.$route.params.id
					}).then(response => {
						this.titulo = response.consult.titulo;
						this.subtitulo = response.consult.subtitulo;
						this.cantidad = response.consult.cantidad;
						//this.imagen = 'images/categorias/'+this.$route.params.id+'.jpg';
						this.productos = response.consult.productos;
						this.resultado = response.consult.productos;
						this.$store.commit('Loading'); 
                    });  
                }
                if (this.$route.params.cond =='marca') {
                    Consulta.Procesar('ProdctMarca',{
						id: this.$route.params.id
					}).then(response => {
						this.titulo = response.consult.titulo;
						this.subtitulo = response.consult.subtitulo;
						this.cantidad = response.consult.cantidad;
						this.imagen = 'images/marcas/'+this.$route.params.id+'.png';
						this.productos = response.consult.productos;
						this.resultado = response.consult.productos;
						this.$store.commit('Loading'); 
                    });  
                }
                if (this.$route.params.cond =='vehiculo') {
                    Consulta.Procesar('ProdctVehiculo',{
						id: this.$route.params.id
					}).then(response => {
						this.titulo = response.consult.titulo;
						this.subtitulo = response.consult.subtitulo;
						this.cantidad = response.consult.cantidad;
						this.imagen = 'images/vehiculos/'+this.$route.params.id+'.png';
						this.productos = response.consult.productos;
						this.resultado = response.consult.productos;
						this.$store.commit('Loading'); 
                    });  
                }

            },
		methods: {
            open(data) {
                this.detalles = data;
                this.display = true;
               
			},
			close() {
				this.display = false;
			},
			Buscar() {
				if (this.buscar==null || this.buscar==''){
					this.productos = this.resultado;

				} else {
					//this.productos = this.resultado.filter(val => val.code == this.buscar);

					this.productos = this.resultado.filter(val => 
						val.code.includes(this.buscar) 
						|| val.name.toUpperCase().includes(this.buscar.toUpperCase())
						|| val.description.toUpperCase().includes(this.buscar.toUpperCase())
						|| val.tipovehiculo.toUpperCase().includes(this.buscar.toUpperCase())
						|| val.vehiculo.toUpperCase().includes(this.buscar.toUpperCase())
					);			
				}
			},
			onSortChange(event){
				const value = event.value.value;
				const sortValue = event.value;

				if (value.indexOf('!') === 0) {
					this.sortOrder = -1;
					this.sortField = value.substring(1, value.length);
					this.sortKey = sortValue;
				}
				else {
					this.sortOrder = 1;
					this.sortField = value;
					this.sortKey = sortValue;
				}
			},
			confirm(data) {
				this.display = false;
                this.detalles = data;
				this.detalles.cantidad = 1;
				if(this.$store.state.authenticated){
					this.confiProducto = true;
				} else {
					this.mensaje = true;
				}
			},
				AgreCarrito(){
					this.$store.commit('AgreCarrito',this.detalles);
					this.confiProducto = false;
				},
            formatCurrency(value) {
                return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                    },

			
		}
	}
</script>

<style scoped lang="scss">
.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	.product-image {
		width: 150px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.marca-image {
		width: 50px;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}

}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

::v-deep(.product-grid-item) {
	margin: .5em;
	border: 1px solid #dee2e6;

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.product-image {
		width: 75%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.marca-image {
		width: 25%;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			width: 75%;
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>
