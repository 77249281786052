<template>
	<div>
		<div class="card">
			<Card>
				<template #content>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-12">
							<h5>Movimientos:</h5>
						</div>
						<div class="p-field p-col-3  p-md-2">
                            <strong>Tasa $</strong>   
						</div>
						<div class="p-field p-col-3  p-md-2">
                            <InputNumber v-model="$store.state.tasa" mode="currency" currency="VEF" locale="en-US"/>
						</div>
						<div class="p-field p-col-12 p-md-12">
							<DataTable :value="$store.state.pago.datos" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id" >
								<Column field="fechad" header="Fecha" :sortable="true">
									<template #body="slotProps">
										{{convfech(slotProps.data.fecha)}}
									</template>
								</Column>
								<Column  header="Forma de Pago" :sortable="true">
									<template #body="slotProps">
										{{slotProps.data.tipo.nombre}}
										<img style="width: 100px;" :src="url.api+'upload/'+slotProps.data.img" v-if="slotProps.data.img && slotProps.data.ext=='jpg'"/>
										<img style="width: 70px;" src="images/pdf.png" v-if="slotProps.data.img && slotProps.data.ext=='pdf'"/>
										<br>{{slotProps.data.referencia}}
									</template>
								</Column>
								<Column header="Monto $" :sortable="true">
									<template #body="slotProps">
										${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}
									</template>
								</Column>
								<Column header="Monto Bs" :sortable="true">
									<template #body="slotProps">
										Bs {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}
									</template>
								</Column>
								<Column header="Eliminar">
									<template #body="slotProps">
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="Eliminar(slotProps.data)"/>
									</template>
								</Column>
							</DataTable>
						</div>
					</div>
				</template>
				<template #footer>						
					<div class="p-fluid p-formgrid p-grid" >
						<div class="p-field p-col-6 p-md-6">
							<h5 v-if="moneda==1">Recibido: <strong>${{formatCurrency(parseFloat($store.state.pago.monto).toFixed(2))}}</strong></h5>
							<h5 v-if="moneda==0">Recibido: <strong>Bs{{formatCurrency(parseFloat($store.state.pago.montobs).toFixed(2))}}</strong></h5>
						</div>
						<div class="p-field p-col-6 p-md-6" v-if="moneda==1">
							<h5>Pendiente: <strong>${{formatCurrency(parseFloat($store.state.pago.deuda - $store.state.pago.monto).toFixed(2))}}</strong></h5>
						</div>
						<div class="p-field p-col-6 p-md-6" v-if="moneda==0">
							<h5>Pendiente: <strong>Bs{{formatCurrency(parseFloat($store.state.pago.deudabs - $store.state.pago.montobs).toFixed(2))}}</strong></h5>
						</div>
						<div class="p-field p-col-4 p-md-4">
							<Button label="Agregar Pago" icon="bi bi-credit-card-2-front" class="p-button-primary" @click="Dialog = true"/>
						</div>
					</div>
				</template>
			</Card>	
		</div>

		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-12 p-md-6">
                        <label>Tipo</label>
                        <div v-for="(stats, index) in tipopago" :key="index">
                            <RadioButton id="tipo" name="tipo" :value="stats" v-model="pago.tipo"/>
                            <strong> {{stats.nombre}}<br><br></strong>
                        </div>
					</div>
                    <div class="p-col-12 p-md-6" v-if="pago.tipo">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <label>Fecha</label>
                                <InputText v-model="pago.fecha" type="date" :max="$store.state.fecha"/>
                            </div>
                            <div class="p-col-12" v-if="!pago.tipo.efectivo">
                                <label>Referencia</label>
                                <InputText v-model="pago.referencia" type="number" placeholder="Número" min="0"/>
                            </div>
                            <div class="p-col-12" v-if="pago.tipo.moneda==1">
                                <label for="price">Monto $</label>
                                <InputNumber v-model="pago.monto" mode="currency" currency="USD" locale="en-US" />  
                            </div>
                            <div class="p-col-12" v-if="pago.tipo.moneda==0">
                                <label for="price">Monto Bs</label>
                                <InputNumber v-model="pago.montobs" mode="currency" currency="VEF" locale="en-US" />
                            </div>
                            <div class="p-col-6" v-if="!pago.tipo.efectivo && pago.img==null">
                                <FileUpload chooseLabel="Subir JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url.api+'upload.php'"  accept=".jpg" :maxFileSize="1000000" @upload="myUploader(token+'.jpg')" :auto="true"/>
                            </div>
                            <div class="p-col-6" v-if="!pago.tipo.efectivo && pago.img==null">
                                <FileUpload chooseLabel="Subir PDF" uploadLabel="Cargar" mode="basic" :name="token" :url="url.api+'upload.php'"  accept=".pdf" :maxFileSize="1000000" @upload="myUploader(token+'.pdf')" :auto="true"/>
                            </div>
                            <div class="p-col-12" v-if="!pago.tipo.efectivo">
								<img style="width: 100px;" :src="url.api+'upload/'+pago.img" v-if="pago.img && pago.ext=='jpg'" @click="Ver(pago.img)"/>
								<img style="width: 70px;" src="images/pdf.png" v-if="pago.img && pago.ext=='pdf'" @click="Ver(pago.img)"/>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="EliminarIMG(pago.img)" v-if="pago.img"/>
                            </div>

                        </div>
                    </div>                   
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

    </div>
</template>

<script>
import API from "../service/API";
import Token from "uuid-token-generator";

export default {
	props: {
		tipopago: {
			type: Array,
			default: null
		},
		deuda: {
			type: Object,
			default: null
		},
		moneda: {
			type: Object,
			default: null
		},	},
    data() {
        return {
			Dialog: false,
            pago: {
                fecha: null
            },
            size: '30vw',
            token: null,
			
		}
	},
    created() {    
		this.$store.commit('LimpiarPagos');
        this.url = this.$store.state.url;
        if (this.$store.state.mobile){this.size = '80vw';}
        this.pago.fecha = this.$store.state.fecha;
		this.Calcular();
        const tokgen = new Token();
        this.token = tokgen.generate();
    },
    methods: {
        Agregar() {
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
			} else if (this.pago.referencia == null && !this.pago.tipo.efectivo) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia del pago', life: 5000});
			} else if ((!this.pago.monto && this.pago.tipo.moneda==1) || (!this.pago.montobs && this.pago.tipo.moneda==0)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			//} else if (this.pago.img == null && !this.pago.tipo.efectivo) {
			//	this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
			} else {
				if(this.pago.tipo.moneda==1) {
					this.pago.montobs = parseFloat(this.pago.monto*this.$store.state.tasa);
				} else {
					this.pago.monto = parseFloat(this.pago.montobs/this.$store.state.tasa);
				}
				if(this.pago.referencia == null){this.pago.referencia = '-';}
				this.$store.state.pago.datos.push({
					id:			this.$store.state.pago.datos.length,
					fecha: 		this.pago.fecha,
					tipo: 		this.pago.tipo,
					monto: 		this.pago.monto,
					montobs: 	this.pago.montobs,
					tasa: 		this.$store.state.tasa,
					img: 		this.pago.img,
					ext: 		this.pago.ext,
					referencia:	this.pago.referencia,
				});
				const tokgen = new Token();
				this.token = tokgen.generate();
				this.pago = {fecha: this.$store.state.fecha};
				this.Calcular();
				this.Dialog = false;
			}
        },
		Eliminar(selec) {
			this.$store.commit('Loading');
			const Consulta = new API('Administracion');
			Consulta.Procesar('ImgPago',{
				caso: 	'Eliminar',
				img: selec.img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){					
                    this.$store.state.pago.datos = this.$store.state.pago.datos.filter(val => val !== selec);
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error'), 
						life: 3000
					});
				}
				this.Calcular();
				this.$store.commit('Loading');
			});			
		},
		Calcular(){
			let total = 0;
			let totalbs = 0;
			for(let sale of this.$store.state.pago.datos) {
				total += sale.monto;
				totalbs += sale.montobs;
			}
			this.$store.state.pago.monto = total;
			this.$store.state.pago.montobs = totalbs;
			this.$store.state.pago.deuda = this.deuda.final;
			this.$store.state.pago.deudabs = this.deuda.finalbs;
		},
		Ver(index){
			window.open(this.url.api+'upload/'+index, '_blank');
		},
		myUploader(img) {
			this.pago.ext = img.slice(-3);
			this.$store.commit('Loading');
			const Consulta = new API('Administracion');
			Consulta.Procesar('ImgPago',{
				caso: 	'Agregar',
				img: img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){					
                    this.pago.img = img;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
		EliminarIMG(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Administracion');
			Consulta.Procesar('ImgPago',{
				caso: 	'Eliminar',
				img: img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){					
                    this.pago.img = null;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});			
		},
        onMenuToggle(event) {
            this.$emit('close-clave', event);
        },	
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        formatCurrency(value) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(value));
        },
	},
    watch: {
		display(newValue) {
			this.displayClave = newValue;
		}
	},


}
</script>