<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb*2" @click="openNew" v-if="insert"/>
					</template>
					<template v-slot:right>
                    </template>
				</Toolbar>
				
				<div class="p-grid p-fluid dashboard" >
					
					<div class="p-col-12 p-lg-6" v-if="$store.state.empresa==0">
						<div class="card summary">
							<span class="title">Empresa</span>
							<span class="detail">
								<Dropdown v-model="empresa" :options="empresas" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="empresa">{{products.length}}</span>
							<span class="count visitors" v-else>0</span>						
						</div>
					</div>
				</div>

				<DataTable ref="dt" :value="products" class="p-datatable-responsive-demo" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Pág {first} a {last} de {totalRecords} productos">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Tipos de Servicios</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true"></Column>
					<Column field="code" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.code}}
						</template>
					</Column>
					<Column field="name" header="Nombre" :sortable="true"></Column>
					<Column field="price" header="Precio" :sortable="true">						
						<template #body="slotProps">
							Precio Mínimo: ${{formatCurrency(parseFloat(slotProps.data.precio).toFixed(2))}} 
							<br>
							<font color="orange">Comisión: {{slotProps.data.comision}}%</font>

						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Estatus</span>
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column  header="Opciones">
						<template #body="slotProps"> 
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-2" @click="confirmDeleteProduct(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Tipo de Servicio" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>ID {{product.id}} {{product.fecha_reg}}				
					<div class="p-field">
						<div class="p-grid">
							<div class="p-col-5">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
								<small class="p-invalid" v-if="submitted && !product.name">Es requerido.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Código</label>
								<InputText id="code" v-model.trim="product.code"/>
							</div>
							<div class="p-col-5">
								<label class="p-mb-3">Inventario de la Empresa</label>
								<Dropdown id="state" v-model="product.empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-12">
								<label for="description">Descripción</label>
								<Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
							</div>
							<div class="p-col-2	">
								<label for="price">Comision %</label>
								<InputNumber id="price" v-model="product.comision" min="0" max="100" />
							</div>							
							<div class="p-col-2">
								<label for="price">Precio Mínimo $</label>
								<InputNumber id="price" v-model="product.precio" mode="currency" currency="USD" locale="de-DE" />
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
	<Simulador :display="displaySimulador" @aprob-simulador="onMenuToggle" @close-simulador="close"/>

</template>

<script>
import API from "../service/API";
import Simulador from '../components/SimuladorPrecio.vue';

export default {
	components: {
		Simulador
	},
	data() {
		return {
			displaySimulador: false,
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			size: '60vw',
			categorias: null,
			categselec: null,
			marcas: null,
			vehiculos: null,
			empresas: [],
			proveedores: null,
			modelos: null,
			seleccion: null,
			empresa: null,
		}
	},
	productService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		this.url = this.$store.state.url;
		if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
	mounted() {
	},
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		Simulador() {
			this.$store.commit('Mobile');
			this.displaySimulador = true;
		},
		close() {
			this.displayPerfil = false;
			this.displaySimulador = false;
		},
		Mostrar() {
			const Consulta = new API('Servicios');
			Consulta.Ini('TipoServicio').then(response => {
				//this.$store.state.error = response.consult.sucategoria;
				this.insert = response.insert;
				this.edit = response.edit;
				this.del = response.delete;
				this.empresas=response.consult.empresas;
				this.$store.commit('Loading');
			}); 
		},
		verproductos() {
			if(this.empresa){
				const Consulta = new API('Servicios');
				Consulta.Procesar('TipoServicio',{
					caso: 'empresa',
					id: this.empresa.id
				}).then(response => {
					//this.$store.state.error = response
					this.products = response.result;
					this.$store.commit('Loading'); 
				});  
			}
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('de-DE', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		saveProduct() {
			if (this.product.name == null || this.product.name == '') {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el nombre del servicio', life: 5000});
			} else if (this.product.code == null || this.product.code == '') {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el código', life: 5000});
			} else if (this.product.description == null || this.product.description == '') {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la descripción', life: 5000});
			} else if (this.product.precio == null || this.product.precio<=0) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el precio', life: 5000});
			} else if (this.product.comision == null || this.product.comision<=0) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el % de comisión', life: 5000});
			} else if (this.product.empresa == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la empresa', life: 5000});
			} else {
			let caso = 'INS';
			if (this.product.id) {caso = 'UPD';}
                const Consulta = new API('Servicios');          
				Consulta.Procesar('TipoServicio',{
					caso: 	caso,
					product: 	this.product,
				}).then(response => {
					//this.$store.state.error=response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Informacion');
						this.productDialog = false;
						this.product = {};
						this.$store.commit('Loading');
						this.verproductos();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});
			}
		},
		editProduct(product) {
			this.product = {...product};
			this.empresas.forEach(element => {
				if(this.product.empresa==element.id){
					this.product.empresa = element;
				}
			});  
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Servicios');          
				Consulta.Procesar('TipoServicio',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Informacion');
						this.seleccion= this.categselec;
						this.$store.commit('Loading');
						this.verproductos();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
		},
			moneda(bs) {
				return bs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 120px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 40px;
}


.p-dialog .product-image {
	width: 100px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
