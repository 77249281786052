<template>
	<div class="p-grid dashboard">
		<div class="p-col-12 p-lg-12 ">
			<i class="bi bi-binoculars" style="color: #d46900;fontSize: 1.5rem"> Búsqueda Dinámica</i>
		</div>	
		<div class="p-col-12 p-lg-3 ">
			<div class="card summary">
				<span class="title">Marca de Vehículo</span>
				<span class="detail">
					<Dropdown v-model="vehiculos_select" :options="vehiculos" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="buscar(1)">
						<template #value="slotProps">
							<div class="p-dropdown-car-value" v-if="slotProps.value">
								<img style="width: 20px;" :alt="slotProps.value.id" :src="'images/vehiculos/' + slotProps.value.id + '.png'" />
								<span> {{slotProps.value.nombre}}</span>
							</div>
							<span v-else>
								{{slotProps.placeholder}}
							</span>
						</template>
						<template #option="slotProps">
							<div class="p-dropdown-car-option">
								<img style="width: 20px;" :alt="slotProps.option.id" :src="'images/vehiculos/' + slotProps.option.id + '.png'" />
								<span> {{slotProps.option.nombre}}</span>
							</div>
						</template>
					</Dropdown>
				</span>
				<span class="count visitors" v-if="vehiculos_select">{{vehiculos_select.cant}}</span>
				<span class="count visitors" v-else>0</span>
				
			</div>
		</div>
		<div class="p-col-12 p-lg-3" v-if="vehiculos_select">
			<div class="card summary">
				<span class="title">Modelo de Vehículo</span>
				<span class="detail">
					<Dropdown v-model="modvehiculos_select" :options="modvehiculos" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="buscar(2)">
						<template #value="slotProps">
							<div class="p-dropdown-car-value" v-if="slotProps.value">
								<span> {{slotProps.value.nombre}}</span>
							</div>
							<span v-else>
								{{slotProps.placeholder}}
							</span>
						</template>
						<template #option="slotProps">
							<div class="p-dropdown-car-option">
								<span> {{slotProps.option.nombre}}</span>
							</div>
						</template>
					</Dropdown>
				</span>
				<span class="count visitors" v-if="modvehiculos_select">{{modvehiculos_select.cant}}</span>
				<span class="count visitors" v-else>0</span>
				
			</div>
		</div>

		<div class="p-col-12 p-lg-3" v-if="modvehiculos_select">
			<div class="card summary">
				<span class="title">Categoría</span>
				<span class="detail">
					<Dropdown id="state" v-model="categorias_select" :options="categorias" optionLabel="nombre" placeholder="Selecciona..." @change="buscar(3)"></Dropdown>
				</span>
				<span class="count visitors" v-if="categorias_select">{{categorias_select.cant}}</span>
				<span class="count visitors" v-else>0</span>

			</div>
		</div>
		<div class="p-col-12 p-lg-3" v-if="categorias_select">
			<div class="card summary">
				<span class="title">Tipo de Producto</span>
				<span class="detail" >
					<Dropdown id="state" v-model="subcategorias_select" :options="subcategorias" optionLabel="nombre" placeholder="Selecciona..." @change="buscar(4)"></Dropdown>
				</span>
				<span class="count visitors" v-if="subcategorias_select">{{subcategorias_select.cant}}</span>
				<span class="count visitors" v-else>0</span>
			</div>
		</div>

		<div class="p-col-12 p-lg-12">
			<div class="p-grid">
				<div class="p-col-2 p-lg-2" v-if="vehiculos_select">
					<img style="width: 100px;" :src="'images/vehiculos/'+vehiculos_select.id+'.png'">
				</div>
				<div class="p-col-2 p-lg-2" v-if="modvehiculos_select">
					<img style="width: 100px;" :src="'images/modelos/'+modvehiculos_select.id+'.jpg'">
				</div>
				<div class="p-col-2 p-lg-2" v-if="categorias_select">
					<img style="width: 100px;" :src="'images/categorias/'+categorias_select.id+'.jpg'">
				</div>
			</div>	
		</div>

		<div class="p-col-12">
			
			<div class="card" >		
				<DataView :value="productos" :layout="layout" :paginator="true" :rows="15" :sortOrder="sortOrder" :sortField="sortField">
					<template #header>
						<div class="p-grid p-nogutter">
							<div class="p-col-6" style="text-align: left">
								<Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Ordenar por ..." @change="onSortChange($event)"/>
							</div>
							<div class="p-col-6" style="text-align: right">
								<DataViewLayoutOptions v-model="layout" />
							</div>
						</div>
					</template>
					<template #list="slotProps">
						<div class="p-col-12">
							<div class="product-list-item">
								<img class="product-image" :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg" :alt="slotProps.data.name"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{slotProps.data.name}}</div>
									<div class="product-name" style="font-size: 16px;">{{slotProps.data.tipovehiculo}} {{slotProps.data.vehiculo}}</div>
									<div class="product-description">
										{{slotProps.data.code}}<br>
										Disponibles: {{slotProps.data.disponibles}}
									</div>
									<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{slotProps.data.category}}
										<strong style="color: orange;"> {{slotProps.data.marca}}</strong>
									</span>
									<div>
										<img  class="marca-image" :src="'images/marcas/'+slotProps.data.marc+'.png'" :alt="slotProps.data.marca" v-if="slotProps.data.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+slotProps.data.veh+'.png'" :alt="slotProps.data.tipovehiculo" v-if="slotProps.data.veh>0"/>
									</div>
								</div>
								<div class="product-list-action">
									<span class="product-price">${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</span>
									<Button class="p-button-warning" icon="pi pi-shopping-cart" label="Agregar al Carrito" :disabled="slotProps.data.stock === 'OUTOFSTOCK'" @click="confirm(slotProps.data)"></Button>
									<Button class="p-button-secondary" icon="pi pi-search-plus" label="Ver Descripción" @click="open(slotProps.data)"></Button>
									<span :class="'product-badge status-'+slotProps.data.stock.toLowerCase()">{{slotProps.data.stock}}</span>
								</div>
							</div>
						</div>
					</template>

					<template #grid="slotProps">
						<div class="p-col-12 p-md-4">
							<div class="product-grid-item card">
								<div class="product-grid-item-top">
									<div>
										<i class="pi pi-tag product-category-icon"></i>
										<span class="product-category" >
											{{slotProps.data.category}}
											<strong style="color: orange;"> {{slotProps.data.marca}}</strong>
										</span>
									</div>
									<span :class="'product-badge status-'+slotProps.data.stock.toLowerCase()">{{slotProps.data.stock}}</span>
								</div>
								<div class="product-grid-item-top">
									<div>
										<img  class="marca-image" :src="'images/marcas/'+slotProps.data.marc+'.png'" :alt="slotProps.data.marca" v-if="slotProps.data.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+slotProps.data.veh+'.png'" :alt="slotProps.data.tipovehiculo" v-if="slotProps.data.veh>0"/>
									</div>
								</div>
								<div class="product-grid-item-content">
									<img  class="product-image" :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg" :alt="slotProps.data.name"/>
									<div class="product-name" style="font-size: 15px;">{{slotProps.data.name}}</div>
									<div class="product-name" style="font-size: 15px;">{{slotProps.data.tipovehiculo}} {{slotProps.data.vehiculo}}</div>
									<div class="product-description">
										{{slotProps.data.code}}<br>
										Disponibles: {{slotProps.data.disponibles}}
									</div>
									<Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
								</div>
								<div class="product-grid-item-bottom">
									<span class="product-price">${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</span>
									<Button class="p-button-warning" icon="pi pi-shopping-cart" :disabled="slotProps.data.stock === 'OUTOFSTOCK'" @click="confirm(slotProps.data)"></Button>
									<Button class="p-button-secondary" icon="pi pi-search-plus" @click="open(slotProps.data)"></Button>
								</div>
							</div>
						</div>
					</template>
				</DataView>
			</div>
		</div>
		<div class="p-col-12 p-lg-12" v-if="!vehiculos_select">
			<img style="display:block;margin:auto;width:400px;" src="logo.png"/>
		</div>	
		
				<Dialog :header="detalles.name" v-model:visible="display" :style="{width: size}" :modal="true" :maximizable="true">
							<Toast/>
							<div class="product-list-item">
								<img class="product-image" :src="$store.state.url_api+'upload_prod/'+detalles.imagen+'?version='+detalles.fecha_reg" :alt="detalles.name"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{detalles.tipovehiculo}} {{detalles.vehiculo}}</div>
                                    <div class="product-description">Código: {{detalles.code}}</div>
									<div class="product-description">{{detalles.description}}</div>
									<Rating :modelValue="detalles.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{detalles.category}}
										<strong style="color: orange;"> {{detalles.marca}}</strong>
									</span>
									<div>
										<img  class="marca-image" :src="'images/marcas/'+detalles.marc+'.png'" :alt="detalles.marca" v-if="detalles.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+detalles.veh+'.png'" :alt="detalles.tipovehiculo" v-if="detalles.veh>0"/>
									</div>
								</div>
							</div>
					<template #footer>
					<Button class="p-button-warning" icon="pi pi-shopping-cart" label="Agregar al Carrito" :disabled="detalles.stock === 'OUTOFSTOCK'" @click="confirm(slotProps.data)"></Button>

						<Button label="Cerrar" @click="close" icon="pi pi-times-circle" class="p-button-danger"/>
					</template>
				</Dialog>

				<Dialog header="Confirmar Agregar al Carrito" v-model:visible="confiProducto" :style="{width: size}" :modal="true">
					<Toast/>
					<br>
							<div class="product-list-item">
								<img :src="$store.state.url_api+'upload_prod/'+detalles.imagen+'?version='+detalles.fecha_reg" :alt="detalles.name" class="product-image"/>
								<div class="product-list-detail">
									<div class="product-name" style="font-size: 16px;">{{detalles.tipovehiculo}} {{detalles.vehiculo}}</div>
									<div class="product-description">{{detalles.description}}</div>
									<Rating :modelValue="detalles.rating" :readonly="true" :cancel="false"></Rating>
									<i class="pi pi-tag product-category-icon"></i>
									<span class="product-category">
										{{detalles.category}}
										<strong style="color: orange;"> {{detalles.marca}}</strong>
									</span>
								</div>
									<img :src="'images/marcas/'+detalles.marc + '.png'" :alt="detalles.marca" class="marca-image" v-if="detalles.marc>0"/>
									<img :src="'images/vehiculos/'+detalles.veh + '.png'" :alt="detalles.tipovehiculo" class="marca-image" v-if="detalles.veh>0"/>
							</div>
					<div class="confirmation-content">
						<i class="pi pi-shopping-cart p-mr-3" style="font-size: 2rem" />
						<span>Indique la cantidad a camprar: </span>
						<InputNumber v-model="detalles.disponibles" min="1" :max="detalles.disponibles" showButtons mode="decimal"></InputNumber>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="AgreCarrito()"/>
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="confiProducto = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="mensaje" :style="{width: size}" header="Mensaje" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span >Para incluir un producto debe logerse primero!</span>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="mensaje = false"/>
					</template>
				</Dialog>

	</div>
</template>

<script>
	import API from "../service/API";
	import url from "../service/_URL";

	export default {
		data() {
			return {
                vehiculos: null,
                vehiculos_select: null,
                modvehiculos: null,
                modvehiculos_select: null,
				categorias: null,
				categorias_select: null,
				subcategorias: null,
				subcategorias_select: null,
				marcas_orig: [],
				marcas: [],
				marcas_select: null,
                origproductos1: [],
                origproductos2: [],
                origproductos3: [],
                origproductos4: [],
                productos: [],
                display: false,
                detalles: {},
				dataviewValue: null,
				layout: 'grid',
				sortKey: null,
				sortOrder: null,
				sortField: null,
				sortOptions: [
					{label: 'Precio de mayor a menor', value: '!preciototal'},
					{label: 'Precio de menor a mayor', value: 'preciototal'},
					{label: 'Nombre', value: 'name'},
					{label: 'Vehículo', value: 'tipovehiculo'},
					{label: 'Marca', value: 'marca'},
                ],
				size: '60vw',
				disponibles: 0,
				filto_categoria: [],
				filto_subcategoria: [],
				filto_marca: [],
				confiProducto: false,
				mensaje: false,
			}
        },
            created() {	
				this.url = url;
				if (this.$store.state.mobile){this.size = '90vw';}
				const Consulta = new API('Productos');
				Consulta.Inf('Dinamica').then(response => {
					//this.$store.state.error=response;
					this.vehiculos = response.consult;
					this.$store.commit('Loading');
				});   
            },
		methods: {
            buscar(caso) {
				if (caso==1 && this.vehiculos_select.id){
					this.modvehiculos_select = null;
					this.categorias_select = null;
					this.subcategorias_select = null;
					const Consulta = new API('Productos');
					Consulta.Procesar('Dinamica',{
						caso: caso,
						id: this.vehiculos_select.id
					}).then(response => {
						this.productos = response.consult.productos;
						this.modvehiculos = response.consult.modvehiculos;
						this.$store.commit('Loading');
					});  
				}				
				if (caso==2 && this.modvehiculos_select.id){
					this.categorias_select = null;
					this.subcategorias_select = null;
					const Consulta = new API('Productos');
					Consulta.Procesar('Dinamica',{
						caso: caso,
						id: this.modvehiculos_select.id
					}).then(response => {
						this.productos = response.consult.productos;
						this.categorias = response.consult.categorias;
						this.$store.commit('Loading');
					});  
				}				
				if (caso==3 && this.categorias_select.id){
					this.subcategorias_select = null;
					const Consulta = new API('Productos');
					Consulta.Procesar('Dinamica',{
						caso: caso,
						modelo: this.modvehiculos_select.id,
						id: this.categorias_select.id
					}).then(response => {
						this.productos = response.consult.productos;
						this.subcategorias = response.consult.subcategorias;
						this.$store.commit('Loading');
					});  
				}				
				if (caso==4 && this.subcategorias_select.id){
					const Consulta = new API('Productos');
					Consulta.Procesar('Dinamica',{
						caso: caso,
						modelo: this.modvehiculos_select.id,
						id: this.subcategorias_select.id
					}).then(response => {
						this.productos = response.consult.productos;
						this.$store.commit('Loading');
					});  
				}				
			},
            open(data) {
                this.detalles = data;
                this.display = true;     
			},
			close() {
				this.display = false;
			},
			onSortChange(event){
				const value = event.value.value;
				const sortValue = event.value;

				if (value.indexOf('!') === 0) {
					this.sortOrder = -1;
					this.sortField = value.substring(1, value.length);
					this.sortKey = sortValue;
				}
				else {
					this.sortOrder = 1;
					this.sortField = value;
					this.sortKey = sortValue;
				}
			},
			confirm(data) {
				this.display = false;
                this.detalles = data;
				this.detalles.disponibles = 1;
				if(this.$store.state.authenticated){
					this.confiProducto = true;
				} else {
					this.mensaje = true;
				}
			},			
				AgreCarrito(){
					this.$store.commit('AgreCarrito',this.detalles);
					this.confiProducto = false;
				},
            formatCurrency(value) {
                return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                    },

		}
	}
</script>

<style scoped lang="scss">
.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 1rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 100%;

	.product-image {
		width: 150px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.marca-image {
		width: 50px;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}

}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

::v-deep(.product-grid-item) {
	margin: .5em;
	border: 1px solid #dee2e6;

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.product-image {
		width: 75%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.marca-image {
		width: 25%;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			width: 75%;
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>
