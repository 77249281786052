<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-col-4">
								<h5>Empresa</h5>
								<Dropdown v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Seleccione la Empresa ..."></Dropdown><br>
								<span class="p-buttonset">
									<Button label="Buscar Producto" icon="pi pi-search" class="p-button-secondary" @click="Productos"/>
									<Button label="Exportar Inventario" icon="pi pi-download" class="p-button-warning" @click="Descargar"/>
								</span>
							</div>
							<div class="p-col-2" v-if="product.id>0">
								<Card style="width:250px;height:250px;">
									<template #content>
										<img :src="$store.state.url_api+'upload_prod/'+product.imagen+'?version='+product.fecha_reg"  style="width:200px;height:200px;" />
									</template>
								</Card>
							</div>
							<div class="p-col-4" v-if="product.id>0">
								<Card style="width:400px;height:250px;">
									<template #title>
									{{product.name}} {{product.code}}
									</template>
									<template #subtitle>
									{{product.tipovehiculo}} {{product.vehiculo}}<br>
									ID: {{product.id}}
									</template>
									<template #content>
										{{product.category}} <strong style="color: orange;"> {{product.marca}}</strong>
									</template>
									<template #footer>
										Disponibles: {{product.disponibles}}
										<img  class="marca-image" :src="'images/marcas/'+product.marc+'.png'" :alt="product.marca" v-if="product.marc>0"/>
										<img  class="marca-image" :src="'images/vehiculos/'+product.veh+'.png'" :alt="product.tipovehiculo" v-if="product.veh>0"/>
									</template>
								</Card>
							</div>
						</div>
					</template>
					<template #content>
						<DataTable :value="inventario" :scrollable="true" scrollHeight="300px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<template #header>
								<div class="table-header">
									<h5 class="p-m-0">Inventario</h5>
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column field="fecha" header="Fecha" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.fecha}}
								</template>								
							</Column>
							<Column field="id" header="ID" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.id}}
								</template>								
							</Column>
							<Column field="pago" header="Pago Nº" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.pago}}
								</template>								
							</Column>
							<Column field="factura" header="Factura Nº" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.factura}}
								</template>								
							</Column>
							<Column field="nota" header="Nota º" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.nota}}
								</template>								
							</Column>
							<Column field="cantidad" header="Cantidad" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.cantidad}}
								</template>								
							</Column>
							<Column field="costo" header="Costo" :sortable="true">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.precio).toFixed(2))}}
								</template>								
							</Column>
							<Column field="tipo" header="Tipo" :sortable="true">
								<template #body="slotProps">
									<strong v-if="slotProps.data.tipo==1" style="color:green">
										Ingreso
									</strong>
									<strong v-else style="color:red">
										Egreso
									</strong>
								</template>								
							</Column>
							<Column header="Opciones"  headerStyle="width: 100px">
								<template #body="slotProps"> 
									<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
									<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" />
								</template>
							</Column>
						</DataTable>
					</template>
					<template #footer>
						<Button label="Agregar" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb*2" @click="newProduct"/>
					</template>
				</Card>	
			</div>
		</div>


				<Dialog v-model:visible="DialogProductos" :style="{width: size}" header="Productos" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<DataTable :value="productos" :scrollable="true" scrollHeight="200px"  v-model:selection="selectedCustomers" :filters="filters2" class="p-datatable-sm">
									<template #header>
										<div class="table-header">
											<h5 class="p-m-0">Buscar </h5>
											<span class="p-input-icon-left">
												<i class="pi pi-search" />
												<InputText v-model="filters2['global']" placeholder="Buscar..." />
											</span>
										</div>
									</template>
									<Column field="id" header="ID" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.id}}
										</template>
									</Column>
									<Column field="name" header="Producto" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.name}}
										</template>
									</Column>
									<Column field="code" header="Código" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.code}}
										</template>
									</Column>
									<Column field="marca" header="Marca" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.marca}}
										</template>
									</Column>
									<Column field="disp" header="Almacen" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.disp}}
										</template>
									</Column>
									<Column field="pedidos" header="Pedido" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.pedidos}}
										</template>
									</Column>
									<Column field="disponibles" header="Disponible" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.disponibles}}
										</template>
									</Column>
									<Column header="Seleccionar">
										<template #body="slotProps">
											<Button icon="pi pi-check-circle" class="p-button-rounded p-button-primary p-mr-2" @click="Buscar(slotProps.data)"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProductos = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="Dialog" :style="{width: size}" header="Editar" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<Card style="width:150px;height:150px;">
									<template #content>
										<img :src="$store.state.url_api+'upload_prod/'+product.imagen"  style="width:100px;height:100px;" />
									</template>
								</Card>
							</div>
							<div class="p-col-4">
								<Card style="width:400px;height:150px;">
									<template #title>
									{{product.name}} {{product.code}}
									</template>
									<template #subtitle>
										{{product.tipovehiculo}} {{product.vehiculo}}<br>
										ID: {{product.id}}
									</template>
									<template #content>
										{{product.category}} <strong style="color: orange;"> {{product.marca}}</strong>
									</template>
								</Card>
							</div>
							<div class="p-col-6">
								<div class="p-grid">
									<div class="p-col-2" v-if="seleccion.id>0">
										ID: {{seleccion.id}}
									</div>
									<div class="p-col-4" v-if="seleccion.id>0">
										Fecha: {{seleccion.fecha}}
									</div>
									<div class="p-col-2" v-if="seleccion.id>0">
										Pago Nº {{seleccion.nota}}
									</div>
									<div class="p-col-2" v-if="seleccion.id>0">
										Factura Nº {{seleccion.factura}}
									</div>
									<div class="p-col-2" v-if="seleccion.id>0">
										Nota Nº {{seleccion.nota}}
									</div>
									<div class="p-col-3">
										<label for="quantity">Cantidad</label>
										<InputNumber id="quantity" v-model="seleccion.cantidad" min="1" integeronly/>
									</div>
									<div class="p-col-3">
										<label for="price">Costo $</label>
										<InputNumber id="price" v-model="seleccion.precio" mode="currency" currency="USD" locale="en-US" />
									</div>
									<div class="p-col-3">
										<label for="price">Tipo</label>
										<SelectButton v-model="seleccion.condicion" :options="options" optionLabel="name"/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size2}" header="Confirmar" :modal="true">
					<br>
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

	</div>

</template>

<script>
import API from "../service/API";
import XLS from "../service/XLS";

export default {
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			Dialog: false,
			DialogProductos: false,
			product: {id:0},
			inventario: [],
			seleccion: {},			
			deleteProductDialog: false,
			filters: {},
			filters2: {},
			submitted: false,
			size: '80vw',
			size2: '40vw',
			imprimir: false,
			empresas: [],
			empresa: null,
			options: [
				{name: 'Ingreso', code: 1},
                {name: 'Egreso', code: -1},
			]
		}
	},
	productService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		this.url = this.$store.state.url;
		this.Mostrar();
	},
	mounted() {
	},
	methods: {
		Mostrar() {
			const Consulta = new API('Inventario');
			Consulta.Ini('Ajuste').then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				this.empresas = result.consult.empresas;
				this.$store.commit('Loading');
			}); 
		},
		Productos() {
			const Consulta = new API('Inventario');
			Consulta.Procesar('Ajuste',{
				caso: 	'Productos',
				empresa: this.empresa.code,
			}).then(response => {
				//this.$store.state.error = response.result;
				this.productos = response.result;
				this.DialogProductos = true;
				this.$store.commit('Loading');
			});
		},
		Buscar(data) {
			this.inventario = [];
			this.product = data;
			const Consulta = new API('Inventario');
			Consulta.Procesar('Ajuste',{
				caso: 	'Buscar',
				producto: data,
			}).then(response => {
				//this.$store.state.error = response.result;
				this.inventario = response.result.inventario;
				this.product.disponibles = response.result.disponibles;
				this.DialogProductos = false;
				this.$store.commit('Loading');
			});
		},
		Descargar(){
			if(this.empresa){
				const Consulta = new API('Inventario');
				Consulta.Procesar('Ajuste',{
					caso: 	'DescargarXLS',
					empresa: this.empresa.code
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$store.commit('Loading');
							const xls = new XLS();
							xls.Inventario(this.empresa,response.result);
						} else {
								this.$store.commit('Loading');
						}
					});
			}
		},
		newProduct() {
			this.seleccion = {
				id: 0,
				cantidad: 1,
				precio: 0,
				condicion:  {name: 'Egreso', code: -1},
			};
			this.Dialog = true;
		},
		editProduct(data) {
			this.seleccion = data;
			if(data.tipo==1){
				this.seleccion.condicion = {name: 'Ingreso', code: 1};
			} else {
				this.seleccion.condicion =  {name: 'Egreso', code: -1};
			}
			this.Dialog = true;
		},
		saveProduct() {
			const Consulta = new API('Inventario');
			if(this.seleccion.id>0){
				Consulta.Procesar('Ajuste',{
					caso: 	'UPD',
					seleccion: this.seleccion,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Dialog = false;
						this.seleccion = {};
						this.$store.commit('Loading');
						this.Buscar(this.product);
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});			
			} else {
				Consulta.Procesar('Ajuste',{
					caso: 	'INS',
					seleccion: this.seleccion,
					product: this.product,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Dialog = false;
						this.seleccion = {};
						this.$store.commit('Loading');
						this.Buscar(this.product);
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});			

			}
		},
		confirmDeleteProduct(data) {
			this.seleccion = data;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
			const Consulta = new API('Inventario');
			Consulta.Procesar('Ajuste',{
				caso: 	'DEL',
				seleccion: this.seleccion,
			}).then(response => {
				//this.$store.state.error = response.result;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.deleteProductDialog = false;
						this.seleccion = {};
						this.$store.commit('Loading');
						this.Buscar(this.product);
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				
			});
		},

		formatCurrency(value) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(value));
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},

		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 50px;
}


.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
