<template>
	<div class="p-grid">
		<div class="p-col-4">
			<br>
			<i class="pi pi-send" style="color: #d46900;fontSize: 1.5rem"> Facturación</i>
		</div>	
		<div class="p-col-3">
			
		</div>	
		<div class="p-col-12" v-if="!mostrar">
			<div class="card">
				<DataTable :value="facturas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Facturas</h5>
							<Dropdown id="state" v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar" style="width: 300px;"></Dropdown>
							<Button label="Orden de Pedido Pendiente" icon="bi bi-hand-thumbs-up" class="p-button-primary" @click="DialogPedidos = true" v-if="!mostrar"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="control" header="Nº Control" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							NE{{slotProps.data.control.toString().padStart(6, 0)}}
						</template>
					</Column>
					<Column field="emp_nombre"  header="Empresa" :sortable="true" headerStyle="width: 320px">
						<template #body="slotProps">
							{{slotProps.data.emp_nombre}}
						</template>
					</Column>
					<Column field="pedido" header="Nº Pedido" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.pedido.toString().padStart(6, 0)}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="cli_nombre"  header="Cliente" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cli_nombre}}
						</template>
					</Column>
					<Column header="Monto" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}
						</template>
					</Column>
					<Column header="Pagado" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.pagado).toFixed(2))}}
						</template>
					</Column>
					<Column header="Deuda" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
                            ${{formatCurrency(parseFloat(slotProps.data.monto-slotProps.data.pagado).toFixed(2))}}
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 100px">
						<template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data)"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="p-col-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h5>Datos de Cliente ({{seleccion.cliente.tiponombre}})</h5> 						
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								<h5><strong>{{seleccion.cliente.code+'-'+seleccion.cliente.rif}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5>Cliente: <strong>{{seleccion.cliente.nombre}}</strong> ({{seleccion.cliente.tiponombre}})</h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5>Correo: <strong>{{seleccion.cliente.correo}}</strong> </h5>
							</div>
							
							<div class="p-field p-col-12 p-md-2">
								Telf: <strong>{{seleccion.cliente.tlflocal}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-10">
								Dirección: {{seleccion.cliente.direccion}} ({{seleccion.cliente.estado}}, {{seleccion.cliente.ciudad}}. Municipio {{seleccion.cliente.municipio}}) 
							</div>
							<div class="p-field p-col-12 p-md-2">
								Cel: <strong>{{seleccion.cliente.celular}}</strong>
							</div>
						</div>
					</template>
				</Card>	
			</div>
		</div>
		
		<div class="p-col-12" v-if="mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h5>Empresa: {{seleccion.empresa.nombre}} Rif: {{seleccion.empresa.code}}-{{seleccion.empresa.rif}}</h5> 						
					</template>
					
					<template #content>
						<DataTable :value="seleccion.productos" :scrollable="true" scrollHeight="300px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<Column header="Código" headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data.id.toString().padStart(8, 0)}}
								</template>
							</Column>
							<Column header="Descripción">
								<template #body="slotProps">
									{{slotProps.data.code}} {{slotProps.data.name}}
								</template>
							</Column>
							<Column field="cantidad" header="Cantidad" headerStyle="width: 100px">
								<template #body="slotProps"  >
									{{slotProps.data.cantidad}}
								</template>
							</Column>
                            <Column header="Precio" headerStyle="width: 100px">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}
									<br>
									Bs.{{formatCurrency(parseFloat(slotProps.data.monto*$store.state.tasa).toFixed(2))}}
								</template>
							</Column>
							<Column header="Descuento" headerStyle="width: 100px">
								<template #body="slotProps"  >
									${{formatCurrency(parseFloat(slotProps.data.monto*(slotProps.data.descuento/100)).toFixed(2))}}
									<br>
									Bs.{{formatCurrency(parseFloat(slotProps.data.monto*$store.state.tasa*(slotProps.data.descuento/100)).toFixed(2))}}
								</template>
							</Column>
							<Column header="SubTotal" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.monto*(1-slotProps.data.descuento/100)*slotProps.data.cantidad).toFixed(2))}}
									<br>
									Bs.{{formatCurrency(parseFloat(slotProps.data.monto*(1-slotProps.data.descuento/100)*$store.state.tasa*slotProps.data.cantidad).toFixed(2))}}
								</template>
							</Column>
                        </DataTable>
					</template>
				</Card>	
			</div>
		</div>		
		<div class="p-col-6" v-if="mostrar">
			<ReportarPago :tipopago="tipopago" :deuda="totales" :moneda="moneda"/>
		</div>
		<div class="p-col-6" v-if="mostrar">
		
			<div class="card">
				
				<Card>
					
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-12" >
								<div class="p-fluid p-formgrid p-grid">
									<div class="p-field p-col-2">
										<h5>Fecha:</h5>
									</div>
									<div class="p-field p-col-2">
										<InputText v-model="fecha" type="date"/>
									</div>
									<div class="p-field p-col-4">
										<h5>Nº Control: {{control}}</h5>
									</div>
									<div class="p-field p-col-4">
										<h5>Nº Pedido: {{seleccion.id}}</h5>
									</div>
								</div>
								<div class="p-fluid p-formgrid p-grid" v-if="$store.state.pago.deuda > $store.state.pago.monto">
									<div class="p-field p-col-2">
										<h5>Credito:</h5>
									</div>
									<div class="p-field p-col-2">
										<InputSwitch v-model="credito"/>
									</div>
									<div class="p-field p-col-6" v-if="credito">
										<h5>Monto: <strong>${{formatCurrency(parseFloat($store.state.pago.deuda-$store.state.pago.monto).toFixed(2))}}</strong></h5>
									</div>
									<div class="p-field p-col-1" v-if="credito">
										<h5>Días:</h5>
									</div>
									<div class="p-field p-col-1" v-if="credito">
										<InputNumber id="minmax" v-model="dias" mode="decimal" :min="0" :max="14" />
									</div>
								</div>
								
							</div>
							
							<div class="p-field p-col-12 p-md-4">
								<h5>Descuento:</h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5><strong>${{formatCurrency(parseFloat(totales.descuento).toFixed(2))}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5><strong>Bs.{{formatCurrency(parseFloat(totales.descuento*$store.state.tasa).toFixed(2))}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5>Subtotal:</h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5><strong>${{formatCurrency(parseFloat(totales.subtotal).toFixed(2))}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5><strong>Bs.{{formatCurrency(parseFloat(totales.subtotal*$store.state.tasa).toFixed(2))}}</strong></h5>
							</div>
						</div>
					</template>
					<template #footer>										
						<div class="p-fluid p-formgrid p-grid" >
							<div class="p-field p-col-12 p-md-4">
								<h3>Monto:</h3> 
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h4><strong>${{formatCurrency(parseFloat(totales.subtotal).toFixed(2))}}</strong></h4>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h4><strong>Bs.{{formatCurrency(parseFloat((totales.subtotal)*$store.state.tasa).toFixed(2))}}</strong></h4>
							</div>
							<div class="p-field p-col-12 p-md-6">
								<Button label="Generar Factura" icon="bi bi-hand-thumbs-up" class="p-button-secondary" @click="Generar" :disabled="$store.state.pago.deuda > parseFloat($store.state.pago.monto + 0.0001) && !credito"/>
							</div>
							<div class="p-field p-col-12 p-md-6">
								<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="mostrar = false"/>
							</div>							

						</div>
					</template>
				</Card>	
			</div>
		</div>

		<Dialog v-model:visible="DialogPedidos" :style="{width: size}" header="Orden de Pedidos" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
					<div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
						<DataTable :value="pedidos" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<template #header>
								<div class="table-header">
									<h5 class="p-m-1">Pedidos</h5>
									<span class="p-input-icon-left">
										<i class="pi pi-search"/>
										<InputText v-model="filters['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column field="id" header="Nº" :sortable="true" headerStyle="width: 80px">
								<template #body="slotProps">
									{{slotProps.data.id}}
								</template>
							</Column>
							<Column field="fechad" header="Fecha" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data.fechad}}
								</template>
							</Column>
							<Column header="Empresa" :sortable="true" headerStyle="width: 250px">
								<template #body="slotProps">
									{{slotProps.data.empresa.nombre}}
								</template>
							</Column>
							<Column  header="Cliente" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.cliente.nombre}}
								</template>
							</Column>
							<Column header="Monto" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}
								</template>
							</Column>
							<Column header="IVA" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.iva).toFixed(2))}}
								</template>
							</Column>
							<Column header="Total" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									${{formatCurrency((parseFloat(slotProps.data.iva)+parseFloat(slotProps.data.monto)).toFixed(2))}}
								</template>
							</Column>
							<Column header="Opciones" headerStyle="width: 100px">
								<template #body="slotProps">
									<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)"/>
									<Button icon="bi bi-check2-circle" class="p-button-rounded p-button-success p-mr-2" @click="Cargar(slotProps.data)" v-if="edit"/>
								</template>
							</Column>
						</DataTable>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogPedidos = false"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import ReportarPago from '@/components/ReportarPago.vue';

	export default {
		components: {
			ReportarPago,
		},
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				pedidos: [],
				facturas: [],
				tipopago: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
                mostrar: false,
                totales: {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0,
                },
				DialogPedidos: false,
				seleccion: null,

				credito: false,
				dias: 7,
				fecha: null,
				control: 0,
				moneda: 1,
				empresa: null,
				empresas: [],
				dataprincipal: [],
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.mostrar = false;
                this.seleccion = null;
				this.empresas = [];
				const Consulta = new API('Administracion');
				Consulta.Ini('Facturacion').then(result => {
					//this.$store.state.error = result.consult.facturas[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.dataprincipal = result.consult.pedidos;
					this.pedidos = result.consult.pedidos;
					this.empresas = result.consult.empresas;
					this.facturas = result.consult.facturas;
					this.tipopago = result.consult.tipopago;
					this.$store.commit('Loading');
				}); 
			},
			Buscar(){
				this.pedidos = this.dataprincipal.filter(val => val.empresa !== this.empresa.id);
			},

			Ver(data){
				const doc = new PDF();
				doc.Pedido(data);
			},
			VerPDF(data){
				const doc = new PDF();
				doc.Factura(data);
			},
			Cargar(data){
				this.credito = false;
				this.dias = 7;
				this.control = 0;

				this.fecha =  this.$store.state.fecha;
                this.totales = {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0
                };
                this.seleccion = data;
                data.productos.forEach(element => {
                    this.totales.subtotal = this.totales.subtotal + parseFloat(element.monto*element.cantidad);
                    this.totales.descuento = this.totales.descuento + parseFloat(element.descuento*element.cantidad);
                    //this.totales.iva = this.totales.iva + parseFloat(element.iva*element.cantidad);
					this.totales.final = this.totales.subtotal - this.totales.descuento + this.totales.iva;
					this.totales.finalbs = 0;
                });

				const Consulta = new API('Administracion');          
				Consulta.Procesar('Facturacion',{
						caso: 	'Control',
						empresa: 	data.empresa.id,
				}).then(response => {
					//this.$store.state.error = response;
					this.control = response.consult.control;
					this.mostrar = true;
					this.DialogPedidos = false;
					this.$store.commit('LimpiarPagos');
					this.$store.commit('Loading');

				}); 
			},
			Generar(){
				if (this.seleccion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione un Pedido Pendiente', life: 5000});
				} else if (!this.credito && this.$store.state.pago.deuda > this.$store.state.pago.monto) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar el pago', life: 10000});
				} else {
					const Consulta = new API('Administracion');          
					Consulta.Procesar('Facturacion',{
						caso: 	'INS',
						seleccion: 	this.seleccion,
						pagos: 		this.$store.state.pago,
						credito: 	this.credito,
						dias: 	this.dias,
						fecha: 	this.fecha,
						tasa: 		this.$store.state.tasa,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
						
					});
				}
			},
            findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            formatCurrency(value) {
				return new Intl.NumberFormat("de-DE").format(parseFloat(value));
            },
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
