<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="movimientos" :scrollable="true" scrollHeight="400px" :loading="loading"
				dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				<!--
				<DataTable :value="movimientos" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
				-->
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Movimientos: {{movimientos.length}}</h5>
							<div>
								<InputText v-model="fecha1" type="date" @change="Buscar"/>
								<InputText v-model="fecha2" type="date" @change="Buscar"/>
							</div>
							<Dropdown id="state" v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..." @change="Buscar" style="width: 300px;"></Dropdown>
							<Button label="Descargar" icon="pi pi-download" class="p-button-success p-mr-2 p-mb*2" @click="Descargar" v-if="empresa"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fechad" header="Fecha" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="control" header="Control" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
					<Column field="descripcion" header="Descripción">
						<template #body="slotProps">
							{{slotProps.data.descripcion}}
						</template>
					</Column>
					<Column field="tipo_nombre" header="Forma de Pago"  headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.tipo_nombre}}
						</template>
					</Column>
					<Column field="referencia" header="Referencia" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.referencia}}
						</template>
					</Column>
					<Column field="monto" header="Monto $" headerStyle="width: 120px">
						<template #body="slotProps">
							<strong v-if="slotProps.data.id==0"> {{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.monto>0 && slotProps.data.moneda==1 && slotProps.data.id!=0" style="color: blue;"> {{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}</strong>
                            <strong v-if="slotProps.data.monto<0 && slotProps.data.moneda==1 && slotProps.data.id!=0" style="color: red;"> {{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}</strong>
						</template>
					</Column>
					<Column field="monto" header="Balance $" headerStyle="width: 120px">
						<template #body="slotProps">
							<strong v-if="slotProps.data.id==0"> {{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.id!=0"> {{formatCurrency(parseFloat(slotProps.data.balance).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.balance<0" style="color: orange;">***</strong>

							
						</template>
					</Column>
					<Column field="monto" header="Monto Bs" headerStyle="width: 120px">
						<template #body="slotProps">
							<strong v-if="slotProps.data.id==0"> {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.montobs>0 && slotProps.data.moneda==0 && slotProps.data.id!=0" style="color: blue;"> {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}</strong>
                            <strong v-if="slotProps.data.montobs<0 && slotProps.data.moneda==0 && slotProps.data.id!=0" style="color: red;"> {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}</strong>
						</template>
					</Column>
					<Column field="monto" header="Balance Bs" headerStyle="width: 120px">
						<template #body="slotProps">
							<strong v-if="slotProps.data.id==0"> {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.id!=0"> {{formatCurrency(parseFloat(slotProps.data.balancebs).toFixed(2))}}</strong>
							<strong v-if="slotProps.data.balancebs<0" style="color: orange;">***</strong>
						</template>
					</Column>
				</DataTable>
            </div>
		</div>

	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
    import XLS from "../service/XLS";

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				fecha1: null,
				fecha2: null,
				empresa: null,
				empresas: [],
				movimientos: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				ProductDialog: false,
				deleteProductDialog: false,
				product: {},
				nuevo: false,
				opcion: {nombre: 'Pendientes', code: 2},
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Pagadas', code: 1},
					{nombre: 'Pendientes', code: 2}
				],
				fecha: null,
                monto: 0,
                montobs: 0,
				saldo: {				
					id: 0, 
					tipo: 0,
					fecha: null, 
					fechad: null, 
					control: "-", 
					descripcion: 'SALDO ANTERIOR', 
					referencia: "-", 
					created: null, 
					fecha_reg: null, 
					monto: 0,
					montobs: 0, 
					factura_id: null, 
					nota_id: null, 
					pago_id: null, 
					cobro_id: null,
					empresa: 0,
					tipopago: 0, 
					activo: true, 
					moneda: null, 
					soporte: null, 
					intercambio_id: null, 
					tasa: null, 
					tipo_nombre: "-",
					nota: null, 
					pago: null, 
					cobro: null,
					intercambio: null,
					balance: 0,
					balancebs: 0,
				}
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
        computed: {
            lastYearTotal() {
                let total = 0;
                for(let sale of this.movientos) {
                    total += sale.monto;
                }

                return this.formatCurrency(total);
            },
            thisYearTotal() {
                let total = 0;
                for(let sale of this.movientos) {
                    total += sale.monto;
                }

                return this.formatCurrency(total);
            }
        },    
		methods: {
			Mostrar() {
				this.empresas = [];
				const Consulta = new API('BancoCaja');
				Consulta.Ini('Contabilidad').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.empresas = result.consult.empresas;
					this.fecha1 = result.consult.fecha1;
					this.fecha2 = result.consult.fecha2;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if(this.empresa && this.fecha1  && this.fecha2 && this.fecha1<=this.fecha2) {
					this.movimientos = [];
					const Consulta = new API('BancoCaja');
					Consulta.Procesar('Contabilidad',{
						caso: 'Buscar',
						empresa: this.empresa.id,
						fecha1: this.fecha1,
						fecha2: this.fecha2,
					}).then(response => {
						//this.$store.state.error = response.result.movimientos[0];
						//this.movimientos = response.result.movimientos;
						this.saldo.fechad = this.convfech(this.fecha1);
						this.saldo.monto = response.result.saldo;
						this.saldo.montobs = response.result.saldobs;
						this.saldo.balance = response.result.saldo;
						this.saldo.balancebs = response.result.saldobs;
						this.movimientos = [this.saldo].concat(response.result.movimientos);
						this.$store.commit('Loading'); 
					});  
				}
			},
			Descargar(){
				if(this.empresa){
					const xls = new XLS();
					xls.Contabilidad(this.empresa,this.movimientos);
				}
			},
			Ver(data){
				//this.$store.state.error = data;
				const doc = new PDF();
				doc.Pedido(data);
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
            formatCurrency(value) {
				return new Intl.NumberFormat("de-DE").format(parseFloat(value));
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
