<template>
	<div>
		<div class="card">
			<Card>
				<template #content>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-12">
							<h5>Detalle de Pagos:</h5>
						</div>
						<div class="p-field p-col-12 p-md-12">
							<DataTable :value="detallepago" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id" >
								<Column field="fechad" header="Fecha" headerStyle="width: 120px">
									<template #body="slotProps">
										<Checkbox id="binary" v-model="slotProps.data.print" :binary="true" />
										{{slotProps.data.fechad}}
									</template>
								</Column>
								<Column  header="Forma de Pago">
									<template #body="slotProps">
										{{slotProps.data.tipo_nombre}}
									</template>
								</Column>
								<Column  header="Referencia" headerStyle="width: 100px">
									<template #body="slotProps">
										{{slotProps.data.referencia}}
										<div v-if="slotProps.data.soporte">
											<img style="width: 50px;" :src="url.api+'upload/'+slotProps.data.soporte" v-if="slotProps.data.soporte.slice(-3)=='jpg'" @click="Ver(slotProps.data.soporte)"/>
											<img style="width: 30px;" src="images/pdf.png" v-if="slotProps.data.soporte.slice(-3)=='pdf'" @click="Ver(slotProps.data.soporte)"/>
										</div>
									</template>
								</Column>
								<Column header="Monto $">
									<template #body="slotProps">
										<div v-if="slotProps.data.moneda==1">${{formatCurrency(parseFloat(slotProps.data.monto).toFixed(2))}}</div>
									</template>
								</Column>
								<Column header="Monto Bs">
									<template #body="slotProps">
										<div v-if="slotProps.data.moneda==0">Bs {{formatCurrency(parseFloat(slotProps.data.montobs).toFixed(2))}}</div>
									</template>
								</Column>
							</DataTable>
						</div>
					</div>
				</template>
				<template #footer>						
					<div class="p-fluid p-formgrid p-grid" >
					</div>
				</template>
			</Card>	
		</div>

    </div>
</template>

<script>

export default {
	props: {
		detallepago: {
			type: Array,
			default: null
		},
	},
    data() {
        return {
			Dialog: false,
            size: '30vw',
			
		}
	},
    created() {    
		this.url = this.$store.state.url;
        if (this.$store.state.mobile){this.size = '80vw';}
    },
    methods: {
		Ver(index){
			window.open(this.url.api+'upload/'+index, '_blank');
		},
        formatCurrency(value) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(value));
		},
	},
    watch: {
		display(newValue) {
			this.displayClave = newValue;
		}
	},


}
</script>