<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb*2" @click="openNew" v-if="insert"/>
					</template>
					<template v-slot:right>
                    </template>
				</Toolbar>
				
				<div class="p-grid p-fluid dashboard" >
					<div class="p-col-12 p-lg-4">
						<div class="card summary">
							<span class="title">Categoría   
								<i :class="seleccion.icon" style="color: #d46900;fontSize: 1.5rem" v-if="seleccion">{{seleccion.cant}}</i>
							</span>
							<span class="detail">
								<Dropdown v-model="seleccion" :options="$store.state.categoria" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<img style="width: 20px;" :alt="slotProps.value.id" :src="'images/categorias/' + slotProps.value.id + '.jpg'" />
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<img style="width: 20px;" :alt="slotProps.option.id" :src="'images/categorias/' + slotProps.option.id + '.jpg'" />
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion">{{seleccion.total}}</span>
							<span class="count visitors" v-else>0</span>
							
						</div>
					</div>
					<!--
					<div class="p-col-3 p-lg-3" v-if="seleccion">
						<img style="width: 150px;" :src="'images/categorias/'+seleccion.id+'.jpg'">
					</div>
					-->
					<div class="p-col-12 p-lg-4">
						<div class="card summary">
							<span class="title">Proveedor</span>
							<span class="detail">
								<Dropdown v-model="seleccion2" :options="proveedores" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos2()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion2">{{products.length}}</span>
							<span class="count visitors" v-else>0</span>						
						</div>
					</div>
					<div class="p-col-12 p-lg-4" v-if="$store.state.empresa==0">
						<div class="card summary">
							<span class="title">Empresa</span>
							<span class="detail">
								<Dropdown v-model="seleccion3" :options="empresas" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  @change="verproductos3()">
									<template #value="slotProps">
										<div class="p-dropdown-car-value" v-if="slotProps.value">
											<span> {{slotProps.value.nombre}}</span>
										</div>
										<span v-else>
											{{slotProps.placeholder}}
										</span>
									</template>
									<template #option="slotProps">
										<div class="p-dropdown-car-option">
											<span> {{slotProps.option.nombre}}</span>
										</div>
									</template>
								</Dropdown>
							</span>
							<span class="count visitors" v-if="seleccion3">{{products.length}}</span>
							<span class="count visitors" v-else>0</span>						
						</div>
					</div>
				</div>

				<DataTable ref="dt" :value="products" class="p-datatable-responsive-demo" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Pág {first} a {last} de {totalRecords} productos">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Productos a la Venta</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Imagen">
						<template #body="slotProps">
							<strong style="font-size: 8px">{{slotProps.data.empresanombre}}</strong><br><br>
							<img :src="$store.state.url_api+'upload_prod/'+slotProps.data.imagen+'?version='+slotProps.data.fecha_reg"  :alt="slotProps.data.id +'.jpg'" class="product-image" /><br>
							<img :src="'images/marcas/'+ slotProps.data.marc +'.png'" :alt="slotProps.data.marca" class="marca-image" v-if="slotProps.data.marc>0"/>
							<img :src="'images/vehiculos/'+ slotProps.data.veh +'.png'" :alt="slotProps.data.tipovehiculo" class="marca-image" v-if="slotProps.data.veh>0"/>
							<a :href="slotProps.data.mercadolibrelink" target="_blank"><img src="images/mercadolibre.png" class="marca-image"  v-if="slotProps.data.mercadolibre"/></a>
						</template>
					</Column>
					<Column field="id" header="ID" :sortable="true"></Column>
					<Column field="code" header="Código" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Code</span>
							{{slotProps.data.code}}<br>
							Disp: {{slotProps.data.disponibles}}<br>
							<font color="red" v-if="slotProps.data.error || slotProps.data.preciominimo==0">Precio: ${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</font>
							<font color="blue" v-else>Precio: ${{formatCurrency(parseFloat(slotProps.data.preciototal).toFixed(2))}}</font>

						</template>
					</Column>
					<Column field="name" header="Nombre" :sortable="true"></Column>
					<Column field="vehiculo" header="Vehiculo" :sortable="true"></Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Estatus</span>
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column>
						<template #body="slotProps"> 
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-2" @click="confirmDeleteProduct(slotProps.data)" />
							<Button icon="bi bi-files" class="p-button-rounded p-button-info p-mr-2" @click="duplicar(slotProps.data)" v-if="insert"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Product Details" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>ID {{product.id}} {{product.fecha_reg}}
					<img :src="'images/marcas/'+product.marc.id+'.png'" :alt="product.marc.nombre" class="marca-image" v-if="product.marc && product.marc.id>0"/>					
					<img :src="'images/vehiculos/'+product.veh.id+'.png'" :alt="product.veh.nombre" class="marca-image" v-if="product.veh && product.veh.id>0"/>					
					<img src="images/mercadolibre.png" class="marca-image"  v-if="product.mercadolibre"/>
					<img :src="$store.state.url_api+'upload_prod/'+product.imagen+'?version='+product.fecha_reg" :alt="product.id+'.jpg'" class="product-image" v-if="product.id>0"/>	
					<FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="product.id" :url="$store.state.url_api+'upload_prod.php'"  accept=".jpg" :maxFileSize="1000000" @upload="myUploader(product.id)" :auto="true" v-if="product.id>0"/>
				
					<div class="p-field">
						<div class="p-grid">
							<div class="p-col-6">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.name" required="true" autofocus :class="{'p-invalid': submitted && !product.name}" />
								<small class="p-invalid" v-if="submitted && !product.name">Es requerido.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Código</label>
								<InputText id="code" v-model.trim="product.code"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Marca de Producto</label>
								<Dropdown id="state" v-model="product.marc" :options="marcas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Marca de Vehiculo</label>
								<Dropdown id="state" v-model="product.veh" :options="vehiculos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Inventario de la Empresa</label>
								<Dropdown id="state" v-model="product.empresa" :options="empresas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-12">
								<label for="description">Descripción</label>
								<Textarea id="description" v-model="product.description" required="true" rows="3" cols="20" />
							</div>
							<div class="p-col-6">
								<label for="name">Modelo de Vehículo</label>
								<InputText id="vehiculo" v-model.trim="product.vehiculo"/>
							</div>
							<div class="p-col-6">
								<label for="name">Vehículo</label>
								<MultiSelect v-model="product.modelos" :options="modelos" optionLabel="name" placeholder="Seleccina el Vehículo" :filter="true" class="multiselect-custom">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.id">
											<div>{{option.name}} ({{option.marca}})</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Select Countries
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<div>{{slotProps.option.name}} ({{slotProps.option.marca}})</div>
										</div>
									</template>
								</MultiSelect>
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Categoría</label>
								<Dropdown id="state" v-model="categselec" :options="categorias" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="quantity">Unidad</label>
								<InputNumber id="quantity" v-model="product.unidad" min="1" integeronly />
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Estatus</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-12">
								<label class="p-mb-3">Subcategoría</label>
								<div class="p-formgrid p-grid" v-if="categselec">
									<div class="p-field-radiobutton p-col-4" v-for="(stats, index) in categselec.items" :key="index">
										<RadioButton id="category3" name="subcateg" :value="stats.id" v-model="product.subcateg" />
										<label for="category3">{{stats.nombre}}</label>
									</div>

								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="SI" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="NO" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
	<Simulador :display="displaySimulador" @aprob-simulador="onMenuToggle" @close-simulador="close"/>

</template>

<script>
import API from "../service/API";
import Simulador from '../components/SimuladorPrecio.vue';

export default {
	components: {
		Simulador
	},
	data() {
		return {
			displaySimulador: false,
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			size: '60vw',
			categorias: null,
			categselec: null,
			marcas: null,
			vehiculos: null,
			empresas: null,
			proveedores: null,
			modelos: null,
			seleccion: null,
			seleccion2: null,
			seleccion3: null
		}
	},
	productService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		this.url = this.$store.state.url;
		if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
	mounted() {
	},
	methods: {
		myUploader(img) {
			const Consulta = new API('Inventario');
			Consulta.Procesar('Productos',{
				caso: 	'RevJPG',
				foto: img,
			}).then(response => {
				//this.$store.state.error =response;
				if(response.result){
					this.product.foto = response.result;
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
				this.$store.commit('Loading');
			});
		},
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		Simulador() {
			this.$store.commit('Mobile');
			this.displaySimulador = true;
		},
		close() {
			this.displayPerfil = false;
			this.displaySimulador = false;
		},
		Mostrar() {
			const Consulta = new API('Inventario');
			Consulta.Ini('Productos').then(response => {
				//this.$store.state.error = response.consult.sucategoria;
				this.insert = response.insert;
				this.edit = response.edit;
				this.del = response.delete;
				this.categorias=response.consult.sucategoria;
				this.marcas=response.consult.marcas;
				this.vehiculos=response.consult.vehiculos;
				this.empresas=response.consult.empresas;
				this.proveedores=response.consult.proveedores;
				this.modelos=response.consult.modelos;
				this.$store.commit('Loading');
			}); 
		},
		verproductos() {
			this.seleccion2 = null;
			this.seleccion3 = null;
			this.categ = this.seleccion.id;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Productos',{
				caso: 'categoria',
				id: this.seleccion.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		verproductos2() {
			this.seleccion = null;
			this.seleccion3 = null;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Productos',{
				caso: 'proveedor',
				id: this.seleccion2.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		verproductos3() {
			this.seleccion = null;
			this.seleccion2 = null;
			const Consulta = new API('Inventario');
            Consulta.Procesar('Productos',{
				caso: 'empresa',
				id: this.seleccion3.id
			}).then(response => {
				this.products = response.result;
				this.$store.commit('Loading'); 
            });  
		},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('de-DE', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		duplicar(product) {
			this.product = {...product};
			this.categorias.forEach(element => {
				if(this.product.categ==element.id){
					this.categselec = element;
				}
			});  
			this.marcas.forEach(element => {
				if(this.product.marc==element.id){
					this.product.marc = element;
				}
			});  
			this.vehiculos.forEach(element => {
				if(this.product.veh==element.id){
					this.product.veh = element;
				}
			});  
			this.empresas.forEach(element => {
				if(this.product.empresa==element.id){
					this.product.empresa = element;
				}
			});  
			this.proveedores.forEach(element => {
				if(this.product.proveedor==element.id){
					this.product.proveedor = element;
				}
			});  
			this.product.id = null;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		saveProduct() {
				if(!this.product.proveedor) {
					this.product.proveedor = {id: false};
				}
				this.productDialog = false;
                const Consulta = new API('Inventario');          
				if (this.product.id) {
					Consulta.Procesar('Productos',{
						caso: 	'UPD',
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error=response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Informacion');
							this.seleccion= this.categselec;
							this.productDialog = false;
							this.product = {};
							this.$store.commit('Loading');
							this.verproductos();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
				} else {
					Consulta.Procesar('Productos',{
						caso: 	'INS',
						product: 	this.product,
					}).then(response => {
                        //this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Informacion');
							this.seleccion= this.categselec;
							this.productDialog = false;
							this.product = {};
							this.$store.commit('Loading');
							this.verproductos();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
				}
				
		},
		editProduct(product) {
			this.product = {...product};
			this.categorias.forEach(element => {
				if(this.product.categ==element.id){
					this.categselec = element;
				}
			});  
			this.marcas.forEach(element => {
				if(this.product.marc==element.id){
					this.product.marc = element;
				}
			});  
			this.vehiculos.forEach(element => {
				if(this.product.veh==element.id){
					this.product.veh = element;
				}
			});  
			this.empresas.forEach(element => {
				if(this.product.empresa==element.id){
					this.product.empresa = element;
				}
			});  
			this.proveedores.forEach(element => {
				if(this.product.proveedor==element.id){
					this.product.proveedor = element;
				}
			});  
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
                const Consulta = new API('Inventario');          
				Consulta.Procesar('Productos',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Informacion');
						this.seleccion= this.categselec;
						this.$store.commit('Loading');
						this.verproductos();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
		},
			moneda(bs) {
				return bs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 120px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 40px;
}


.p-dialog .product-image {
	width: 100px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
