<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<h5>Datos de Cliente</h5>						
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-1">
								<label for="name">Tipo</label>
								<Dropdown v-model="cliente.code" :options="codes" optionLabel="nombre" placeholder="..."></Dropdown>
							</div>
							<div class="p-field p-col-12 p-md-2">
								<label for="name">RIF</label>
								<InputNumber v-model="cliente.rif" integeronly required="true" min="1" @keyup.enter="BuscarCliente"/>
							</div>
							<div class="p-field p-col-12 p-md-5" v-if="cliente.nombre">
								<h5>Cliente: <strong>{{cliente.nombre}}</strong> ({{cliente.tiponombre}})</h5>
							</div>
						</div>
					</template>
					<template #footer>
						<Button label="Buscar Cliente" icon="bi bi-person-plus" class="p-button-secondary p-mr-2 p-mb*2" @click="BuscarCliente"/>
					</template>
				</Card>	
			</div>
		</div>
		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #title>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-4">
								<Dropdown v-model="empresa" :options="empresas" optionLabel="nombre" placeholder="Seleccione la Empresa ..."></Dropdown>
							</div>
							<div class="p-field p-col-2">
								<Dropdown v-model="tipo" :options="tipos_venta" optionLabel="nombre" placeholder="Seleccione ..."></Dropdown>
							</div>
							<div class="p-col-4" v-if="empresa">
								<Button label="Agregar Servicio" icon="bi bi-plus-square" class="p-button-secondary p-mr-2 p-mb*2" @click="Productos"/>
							</div>
						</div>
					</template>
					<template #content>
						<DataTable :value="pedidos" :scrollable="true" scrollHeight="300px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<template #header>
								<div class="table-header">
									<h5 class="p-m-0">Servicios</h5>
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column header="Código" :sortable="true" headerStyle="width: 150px">
								<template #body="slotProps">
									{{slotProps.data.code}}<br>
									ID{{slotProps.data.id}}
								</template>
							</Column>
							<Column header="Descripción" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.name}}
								</template>
							</Column>
							<Column field="name" header="Cantidad" headerStyle="width: 75px">
								<template #body="slotProps">
									{{slotProps.data.cantidad}}
								</template>
							</Column>
							<Column header="Precio" headerStyle="width: 75px">
								<template #body="slotProps">
									Bs.{{formatCurrency(parseFloat(slotProps.data.precio*$store.state.tasa).toFixed(2))}}
									<br>
									${{formatCurrency(parseFloat(slotProps.data.precio).toFixed(2))}}
								</template>
							</Column>
							<Column header="SubTotal" headerStyle="width: 75px">
								<template #body="slotProps">
									Bs.{{formatCurrency(parseFloat(slotProps.data.precio*$store.state.tasa).toFixed(2))}}
									<br>
									${{formatCurrency(parseFloat(slotProps.data.precio).toFixed(2))}}
								</template>
							</Column>
							<Column header="IVA" headerStyle="width: 75px">
								<template #body="slotProps">
									Bs.{{formatCurrency(parseFloat(slotProps.data.iva*$store.state.tasa*slotProps.data.precio).toFixed(2))}}
									<br>
									${{formatCurrency(parseFloat(slotProps.data.iva*slotProps.data.precio).toFixed(2))}}
								</template>
							</Column>
							<Column headerStyle="width: 75px">
								<template #body="slotProps"> 
									<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="DeleteProduct(slotProps.data)" />
								</template>
							</Column>
						</DataTable>
					</template>
					<template #footer>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-col-3"></div>
							<div class="p-col-3">
								<h6>IVA:</h6>							
							</div>
							<div class="p-col-3">
								<h6>${{formatCurrency(parseFloat(montoiva).toFixed(2))}}</h6>							
							</div>
							<div class="p-col-3">
								<h6>Bs.{{formatCurrency(parseFloat(montoiva*$store.state.tasa).toFixed(2))}}</h6>							
							</div>
						</div>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-col-3"></div>
							<div class="p-col-3">
								<h6>Monto a Cancelar:</h6>							
							</div>
							<div class="p-col-3">
								${{formatCurrency(parseFloat(monto).toFixed(2))}}
							</div>
							<div class="p-col-3">
								Bs.{{formatCurrency(parseFloat(monto*$store.state.tasa).toFixed(2))}}
							</div>
						</div>
					</template>
				</Card>	
			</div>
		</div>

		<div class="p-col-12">
			<div class="card">
				<Card>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-col-4">
								<h4>Monto a Cancelar:</h4>
							</div>
							<div class="p-col-4">
								<h4 v-if="imprimir">${{formatCurrency(parseFloat(monto+montoiva).toFixed(2))}}</h4>							
								<h4 v-else>${{formatCurrency(parseFloat(monto).toFixed(2))}}</h4>							
							</div>
							<div class="p-col-4">
								<h4 v-if="imprimir">Bs.{{formatCurrency(parseFloat((monto+montoiva)*$store.state.tasa).toFixed(2))}}</h4>
								<h4 v-else>Bs.{{formatCurrency(parseFloat(monto*$store.state.tasa).toFixed(2))}}</h4>
								<h5 v-if="imprimir">Incluye IVA</h5>
								<h5 v-else>No Incluye IVA</h5>							
							</div>
							<div class="p-col-6"></div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Imprimir Factura</h6>
							</div>
							<div class="p-col-2">
								<InputSwitch v-model="imprimir"/>
							</div>
							<div class="p-col-2">
								<Button label="Generar Servicio" icon="bi bi-hand-thumbs-up" class="p-button-secondary p-mr-2 p-mb*2" @click="GenerarOrden"/>
							</div>
						</div>
					</template>
				</Card>	
			</div>
		</div>

				<Dialog v-model:visible="DialogProductos" :style="{width: size}" header="Servicios Disponibles" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<DataTable :value="productos" :scrollable="true" scrollHeight="200px"  v-model:selection="selectedCustomers" :filters="filters2" class="p-datatable-sm">
									<template #header>
										<div class="table-header">
											<h5 class="p-m-0">Buscar </h5>
											<span class="p-input-icon-left">
												<i class="pi pi-search" />
												<InputText v-model="filters2['global']" placeholder="Buscar..." />
											</span>
										</div>
									</template>
									<Column field="name" header="Producto" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.name}}
										</template>
									</Column>
									<Column field="code" header="Código" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.code}}
										</template>
									</Column>
									<Column field="description" header="Descripción" :sortable="true">
										<template #body="slotProps">
											{{slotProps.data.description}}
										</template>
									</Column>
									<Column field="preciototal" header="Precio" :sortable="true">
										<template #body="slotProps">
											<InputNumber id="price" v-model="slotProps.data.precio" mode="currency" currency="USD" locale="de-DE" />
										</template>
									</Column>
									<Column field="disponibles" header="Opciones" :sortable="true">
										<template #body="slotProps">
											<Button icon="bi bi-plus-square" class="p-button-rounded p-button-success p-mr-2" @click="AgregarProducto(slotProps.data)" v-if="edit"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProductos = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="Dialog" :style="{width: size}" header="Nuevo Cliente" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-1">
								<label for="name">RIF</label>
								<Dropdown v-model="cliente.code" :options="codes" optionLabel="nombre" placeholder="..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">-</label>
								<InputNumber v-model="cliente.rif" integeronly required="true" min="1"/>
							</div>
							<div class="p-col-9">
								<label for="name">Nombre del Cliente</label>
								<InputText id="name" v-model.trim="cliente.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !cliente.nombre">Name is required.</small>
							</div>
							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="cliente.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !cliente.nombre">Name is required.</small>
							</div>
							<div class="p-col-3">
								<label>Telf Local</label>
								<InputMask v-model="cliente.tlflocal" mask="(9999) 999-9999" />
							</div>                      
							<div class="p-col-3">
								<label>Telf Celular</label>
								<InputMask v-model="cliente.celular" mask="(9999) 999-9999" />
							</div>
							<div class="p-col-6">
								<label for="name">Instagram</label>
								<InputText id="name" v-model.trim="cliente.instagram"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Contribuyente</h6>
								<InputSwitch v-model="cliente.contribuyente" />
							</div>
						</div>
						<h5>Dirección</h5>			
						<div class="p-fluid p-formgrid p-grid">        
							<div class="p-col-4">
								<label>Estado</label>
								<Dropdown v-model="cliente.estado" :options="estados" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label>Ciudad</label>
								<Dropdown v-model="cliente.ciudad" :options="ciudades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label>Municipio</label>
								<Dropdown v-model="cliente.municipio" :options="municipios" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-10">
								<label>Dirección Detallada</label>
								<Textarea v-model="cliente.direccion"  rows="2"/>						
							</div>
							<div class="p-col-2">
								<label for="name">Código Postal</label>
								<InputMask v-model="cliente.postal" mask="9999" />
							</div>
						</div>	
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="AgregarCliente()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="DialogMec" :style="{width: size2}" header="Mano de Obra" :modal="true" class="p-fluid" :maximizable="true">
					<Toast/>
					<br>
                    <div class="p-field">
						<div class="p-grid">{{manodeobras}}
							<div class="p-col-8">
								<label for="name">Mano de Obra</label>
								<Dropdown v-model="mano.product" :options="manodeobras" optionLabel="name" placeholder="..."></Dropdown>
							</div>
							<!--
							<div class="p-col-2">
								<label for="name">-</label>
								<InputNumber v-model="cliente.rif" integeronly required="true" min="1"/>
							</div>
							<div class="p-col-9">
								<label for="name">Nombre del Cliente</label>
								<InputText id="name" v-model.trim="cliente.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !cliente.nombre">Name is required.</small>
							</div>
							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="cliente.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !cliente.nombre">Name is required.</small>
							</div>
							<div class="p-col-3">
								<label>Telf Local</label>
								<InputMask v-model="cliente.tlflocal" mask="(9999) 999-9999" />
							</div>                      
							<div class="p-col-3">
								<label>Telf Celular</label>
								<InputMask v-model="cliente.celular" mask="(9999) 999-9999" />
							</div>
							<div class="p-col-6">
								<label for="name">Instagram</label>
								<InputText id="name" v-model.trim="cliente.instagram"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Contribuyente</h6>
								<InputSwitch v-model="cliente.contribuyente" />
							</div>
							-->
						</div>
					</div>
					<template #footer>
						<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="AgregarMano()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="DialogMec = false"/>
					</template>
				</Dialog>


	</div>

</template>

<script>
import API from "../service/API";

export default {
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			categ: null,
			products: null,
			Dialog: false,
			DialogProductos: false,
			DialogMec: false,
			product: {},
			selectedProducts: null,
			selectedProducts2: null,
			filters: {},
			filters2: {},
			submitted: false,
			size: '80vw',
			size2: '40vw',
			categorias: null,
			categselec: null,
			marcas: null,
			vehiculos: null,
			modelos: null,
			seleccion: null,
			monto: 0,
			montoiva: 0,


			tipos: [],
			clientes: [],
			estados: [],
			ciudades: [],
			municipios: [],
			cliente: {},
			codes: [
				{code: 'V', nombre: 'V'},
				{code: 'J', nombre: 'J'},
				{code: 'G', nombre: 'G'}
			],
			imprimir: false,
			pedidos: [],
			empresas: [],
			empresa: null,
			tipos_venta: [],
			tipo: null,
			manodeobras: [],
			mano : {
				product: null,
				monto: 0,

			}
		}
	},
	productService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
	mounted() {
	},
	methods: {
		Mostrar() {
			this.pedidos = [];
			this.cliente = {};
			this.monto = 0;
			this.montoiva = 0;
			const Consulta = new API('Servicios');
			Consulta.Ini('CrearServicio').then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				this.tipos = result.consult.tipos;
				this.estados = result.consult.estados;
				this.empresas = result.consult.empresas;
				this.manodeobras = result.consult.manodeobra;
				this.tipos_venta = result.consult.tipos_venta;
				this.$store.commit('Loading');
			}); 
		},
		Productos() {
			const Consulta = new API('Servicios');
			Consulta.Procesar('CrearServicio',{
				caso: 	'Productos',
				empresa: this.empresa.code,
			}).then(response => {
				//this.$store.state.error = response
				this.productos = response.result;
				this.DialogProductos = true;
				this.$store.commit('Loading');
			});
		},
		AgregarProducto(data) {
			this.$store.commit('Loading');
			if(!this.pedidos.includes(data)){
				this.pedidos.push(data);
			} 
            this.Monto();
			this.$store.commit('Loading');
		},
		ExportarCarrito() {
			this.$store.commit('Loading');
            this.$store.state.carrito.forEach(element => {
				if (element.empresa == this.empresa.code){
					this.pedidos.push(element);
					this.monto = this.monto + (element.preciototal*element.cantidad);
					this.montoiva = this.montoiva + (element.iva*element.cantidad);
					this.$store.commit('DelCarrito',element);
				}
            });
			this.Monto();
			this.$store.commit('Loading');
		},
		BuscarCliente() {
			if (this.cliente.code == null || this.cliente.rif == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Rif', life: 5000});
			} else {
				this.cliente = {
					code: this.cliente.code,
					rif: this.cliente.rif
				};
				this.parroquias = [];
				const Consulta = new API('Servicios');
				Consulta.Procesar('CrearServicio',{
					caso: 	'Cliente',
					code: this.cliente.code.code,
					rif: this.cliente.rif,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result.length>0){
						this.cliente = response.result[0];
						this.codes.forEach(element => {
							if(this.cliente.code==element.code){this.cliente.code = element}
						}); 
					} else {
						this.Dialog = true;
					}
					this.$store.commit('Loading');
				});
			}
		},
		Validacion() {
			this.parroquias = [];
			const Consulta = new API('Servicios');
			Consulta.Procesar('CrearServicio',{
				caso: 	'Validacion',
				estado: this.cliente.estado.code,
			}).then(response => {
				//this.$store.state.error = response;	
				this.ciudades = response.result.ciudades;
				this.municipios = response.result.municipios;
				this.ciudades.forEach(element => {
					if(this.product.ciudad==element.code){
						this.product.ciudad = element;
					}
				});
				this.municipios.forEach(element => {
					if(this.product.municipio==element.code){
						this.product.municipio = element;
					}
				});
				this.$store.commit('Loading');
			});
		},
		GenerarOrden(){
			if (this.cliente.nombre == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Cliente', life: 5000});
			} else if (this.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe seleccionar el tipo de venta', life: 5000});
			} else if (this.pedidos.length == 0) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe incluir al menos un producto', life: 5000});
			} else {
				const Consulta = new API('Servicios');          
				Consulta.Procesar('CrearServicio',{
					caso: 	'INS2',
					empresa: this.empresa,
					cliente: this.cliente,
					pedidos: this.pedidos,
					imprimir: this.imprimir,
					monto: this.monto,
					iva: this.montoiva,
					tipo: this.tipo,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Loading');
						this.Mostrar();
						this.$router.push({ path: '/servicios' });
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
					
				});
			}
		},
		AgregarCliente() {
			if (this.cliente.code == null || this.cliente.rif == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Rif', life: 5000});
			} else if (this.cliente.nombre == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
			} else if (this.cliente.correo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
			} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
				test(this.cliente.correo)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
			} else if (this.cliente.tlflocal == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tlf Local', life: 5000});
			} else if (this.cliente.celular == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Tlf Celular', life: 5000});
			} else if (this.cliente.estado == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado', life: 5000});
			} else if (this.cliente.ciudad == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ciudad', life: 5000});
			} else if (this.cliente.municipio == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Municipio', life: 5000});
			} else if (this.cliente.direccion == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Dirección', life: 5000});
			} else if (this.cliente.postal == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Código Postal', life: 5000});
			} else {
				const Consulta = new API('Servicios');          
				Consulta.Procesar('CrearServicio',{
					caso: 	'INS1',
					cliente: this.cliente,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Dialog = false;
						this.$store.commit('Loading');
						this.BuscarCliente();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
					
				});
			} 
		},
		formatCurrency(value) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(value));
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		DeleteProduct(product) {
			this.pedidos = this.pedidos.filter(val => val.id !== product.id);
            this.Monto();
		},
		Monto() {
            this.monto = 0;
            this.montoiva = 0;
            this.pedidos.forEach(element => {
                this.monto = this.monto + parseFloat(element.precio)*parseFloat(element.cantidad);
                this.montoiva = this.montoiva + parseFloat(element.iva)*parseFloat(element.cantidad)*parseFloat(element.precio);
            });
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.marca-image {
	width: 50px;
}


.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
