<template>
<div class="p-grid p-fluid dashboard">
	<div class="p-col-12 p-lg-12">
		<div class="p-grid">
			<div class="p-col-5 p-lg-2">
				<label for="name">Desde</label>
				<InputText v-model="fecha1" type="date" @keyup.enter="Buscar"/>
			</div>
			<div class="p-col-5 p-lg-2">
				<label for="name">Hasta</label>
				<InputText v-model="fecha2" type="date" @keyup.enter="Buscar"/>
			</div>
			<div class="p-col-2 p-lg-1" style="vertical-align:bottom ;">
				<Button icon="pi pi-search" class="p-button-rounded p-button-secondary" @click="Buscar"/>
			</div>


		</div>
	</div>	
	<div class="p-col-12 p-lg-12">
        <Accordion :activeIndex="1">
            <AccordionTab :header="item.nombre" v-for="(item, index) in empresas" :key="index">
				<div class="p-grid">

					<div class="p-col-12 p-lg-4">
						<div class="p-grid">
							<div class="p-col-12 p-lg-6">
								<div class="card summary" style="height: 120px;">
									<span class="title">Ventas</span>
									<span class="detail">
										
										Tienda: ${{formatCurrency(item.ventas1)}}
										<br>
										Digital: ${{formatCurrency(item.ventas2)}}
										<br>
										Por Cobrar: ${{formatCurrency(item.ventas4)}}
									</span>
									<span class="count revenue">${{formatCurrency(item.ventas)}}</span>
								</div>
							</div>						
							<div class="p-col-12 p-lg-6">
								<div class="card summary" style="height: 120px;">
									<span class="title">Ingresos</span>
									<span class="detail">
										<br>
										Por Cobrar: ${{formatCurrency(item.ingresos1)}}
										<br>
														
									</span>
									<span class="count revenue">${{formatCurrency(item.ingresos)}}</span>
								</div>
							</div>
							<div class="p-col-12 p-lg-6">
								<div class="card summary" style="height: 120px;">
									<span class="title">Gastos</span>
									<span class="detail">
										<br>
										Por Pagar: ${{formatCurrency(item.gastos1)}}									
									</span>
									<span class="count visitors">${{formatCurrency(item.gastos)}}</span>
								</div>
							</div>
							<div class="p-col-12 p-lg-6">
								<div class="card summary" style="height: 120px;">
									<span class="title">Deudas</span>
									<span class="detail">
										<br>										
									</span>
									<span class="count deudas">${{formatCurrency(item.gastos2)}}</span>
								</div>
							</div>

							<div class="p-col-12 p-lg-6"  style="height: 150px;">
								<div class="highlight-box">
									<div class="initials" style="background-color: #20d077; color: #038d4a; width: 30%;"><span>$</span></div>
									<div class="highlight-details ">
										<i class="bi bi-cash"></i>
										<span>Caja</span>
										<span class="count">{{formatCurrency(item.caja)}}</span>
									</div>
								</div>
							</div>
							<div class="p-col-12 p-lg-6" style="height: 150px;">
								<div class="highlight-box">
									<div class="initials" style="background-color: #f9c851; color: #b58c2b; width: 30%;"><span>Bs</span></div>
									<div class="highlight-details ">
										<i class="bi bi-credit-card"></i>
										<span>Banco</span>
										<span class="count">{{formatCurrency(item.banco)}}</span>
									</div>
								</div>
							</div>








						</div>
					</div>
					<div class="p-col-12 p-lg-4">
						<div class="p-grid">

						</div>
					</div>

					<div class="p-col-12 p-lg-4">
						<div class="p-grid">


							<div class="p-col-12 p-lg-12">
								<div class="card">
									<Chart type="line" :data="Grafica" />
								</div>
							</div>


							<div class="p-col-12 p-lg-12" v-if="item.ventas3.length>0">
								<div class="card summary">
									<DataTable :value="item.ventas3" responsiveLayout="scroll" :scrollable="true" scrollHeight="100px" >
										<Column field="tipo_nombre" header="Venta por"></Column>
										<Column header="Monto">
											<template #body="slotProps">
												${{formatCurrency(parseFloat(slotProps.data.sum).toFixed(2))}}
											</template>
										</Column>
										<Column header="Comisión">
											<template #body="slotProps">
												${{formatCurrency(parseFloat(slotProps.data.sum*0.05).toFixed(2))}}
											</template>
										</Column>
									</DataTable>
								</div>
							</div>



						</div>
					</div>




				</div>
			</AccordionTab>
        </Accordion>		
	</div>	

	<!--		
	<div class="p-col-12 p-lg-4" v-for="(item, index) in empresas" :key="index">
		<Panel :header="item.nombre" style="height: 100%" >
			<div class="p-grid">
				<div class="p-col-12 p-lg-6">
					<div class="card summary" style="height: 120px;">
						<span class="title">Ventas</span>
						<span class="detail">
							
							Tienda: ${{formatCurrency(item.ventas1)}}
							<br>
							Digital: ${{formatCurrency(item.ventas2)}}
							<br>
							Por Cobrar: ${{formatCurrency(item.ventas4)}}
						</span>
						<span class="count revenue">${{formatCurrency(item.ventas)}}</span>
					</div>
				</div>
				<div class="p-col-12 p-lg-6">
					<div class="card summary" style="height: 120px;">
						<span class="title">Ingresos</span>
						<span class="detail">
							<br>
							Por Cobrar: ${{formatCurrency(item.ingresos1)}}
							<br>
											
						</span>
						<span class="count revenue">${{formatCurrency(item.ingresos)}}</span>
					</div>
				</div>
				<div class="p-col-12 p-lg-6">
					<div class="card summary" style="height: 120px;">
						<span class="title">Gastos</span>
						<span class="detail">
							<br>
							Por Pagar: ${{formatCurrency(item.gastos1)}}
							
						</span>
						<span class="count visitors">${{formatCurrency(item.gastos)}}</span>
					</div>
				</div>
				<div class="p-col-12 p-lg-6">
					<div class="card summary" style="height: 120px;">
						<span class="title">Deudas</span>
						<span class="detail">
							<br>
							
						</span>
						<span class="count deudas">${{formatCurrency(item.gastos2)}}</span>
					</div>
				</div>
				
				<div class="p-col-12 p-lg-6">
					<div class="highlight-box">
						<div class="initials" style="background-color: #20d077; color: #038d4a; width: 30%;"><span>$</span></div>
						<div class="highlight-details ">
							<i class="bi bi-cash"></i>
							<span>Caja</span>
							<span class="count">{{formatCurrency(item.caja)}}</span>
						</div>
					</div>
				</div>
				<div class="p-col-12 p-lg-6">
					<div class="highlight-box">
						<div class="initials" style="background-color: #f9c851; color: #b58c2b; width: 30%;"><span>Bs</span></div>
						<div class="highlight-details ">
							<i class="bi bi-credit-card"></i>
							<span>Banco</span>
							<span class="count">{{formatCurrency(item.banco)}}</span>
						</div>
					</div>
				</div>

				<div class="p-col-12 p-lg-12" v-if="item.ventas3.length>0">
					<div class="card summary">
						<DataTable :value="item.ventas3" responsiveLayout="scroll" :scrollable="true" scrollHeight="100px" >
							<Column field="tipo_nombre" header="Venta por"></Column>
							<Column header="Monto">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.sum).toFixed(2))}}
								</template>
							</Column>
							<Column header="Comisión">
								<template #body="slotProps">
									${{formatCurrency(parseFloat(slotProps.data.sum*0.05).toFixed(2))}}
								</template>
							</Column>
						</DataTable>
					</div>
				</div>


			</div>

		</Panel>
	</div>
	-->



</div>
</template>

<script>
import API from "../service/API";


export default {
	data() {
		return {
			fecha1: null,
			fecha2: null,
			empresas:[],
			Grafica: {
				labels: [],
				datasets: [
					{
						label: 'Ventas',
						data: [65, 59, 80, 81, 56, 55],
						fill: false,
						backgroundColor: '#1B61DA',
						borderColor: '#1B61DA'
					},
					{
						label: 'Gastos',
						data: [28, 48, 40, 19, 86, 27],
						fill: false,
						backgroundColor: '#EDA215',
						borderColor: '#EDA215'
					}
				]
			},

		}
	},
	productService: null,
	eventService: null,
	created() {
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
	mounted() {

	},
	methods: {
		Mostrar() {
			const Consulta = new API('Dashboard');
			Consulta.Ini('Dashboard').then(result => {
				//this.$store.state.error = result.consult.mes;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				this.fecha1 = result.consult.fecha;
				this.fecha2 = result.consult.fecha;
				this.empresas = result.consult.empresas;
				this.Grafica.labels = result.consult.mes;
				this.$store.commit('Loading');
			}); 
		},
		Buscar() {
			if(this.fecha1  && this.fecha2 && this.fecha1<=this.fecha2) {
				const Consulta = new API('Dashboard');
				Consulta.Procesar('Dashboard',{
					caso: 'Buscar',
					fecha1: this.fecha1,
					fecha2: this.fecha2,
				}).then(response => {
					//this.$store.state.error = response.result.empresas;
					this.empresas = response.result.empresas;
					this.$store.commit('Loading'); 
				});  
			}
		},
        formatCurrency(value) {
			return new Intl.NumberFormat("de-DE").format(parseFloat(value));
		},

	}
}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 960px) {
		::v-deep(.p-datatable) {
			&.p-datatable-customers {
				.p-datatable-thead > tr > th,
				.p-datatable-tfoot > tr > td {
					display: none !important;
				}

				.p-datatable-tbody > tr {
					border-bottom: 1px solid #dee2e6;
					> td {
						text-align: left;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 0 none !important;
						width: 100% !important;
						float: left;
						clear: left;
						border: 0 none;

						.p-column-title {
							padding: .4rem;
							min-width: 30%;
							display: inline-block;
							margin: -.4rem 1rem -.4rem -.4rem;
							font-weight: bold;
						}

						.p-progressbar {
							margin-top: .5rem;
						}
					}
				}
			}
		}
	}
</style>
